<template>
  <div class="loader">
    <Logo />
    <br />
    <Text is="h3" centered> {{ t('loader.title') }} </Text>
    <br />
    <Text>
      {{ t('loader.message') }}
    </Text>
    <br />
    <Spinner :size="80" />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'LoaderComponent'
})

const { t } = useI18n()
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: var(--size-60);
  background-color: var(--color-neutral-80);
  color: var(--color-neutral-10);
  z-index: var(--z-index-medium);
}
</style>
