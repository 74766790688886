import { ref, computed } from 'vue'
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'

const currentUser = ref<User | null>(null)
const authReady = ref(false) // New ref to track when auth state is determined
const auth = getAuth()

onAuthStateChanged(auth, (user) => {
  currentUser.value = user
  authReady.value = true // Set to true once auth state is determined
})

const isAuthenticated = computed(() => currentUser.value !== null)

export function useAuth() {
  return { currentUser, isAuthenticated, authReady }
}
