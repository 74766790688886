import { TypeSpaces } from './spaces'

const date = new Date()
const day = date.getDay()
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const dias = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado'
]

const today = days[day]
const hoy = dias[day]

export const Nature: TypeSpaces = {
  'sandy-beach': {
    en: {
      labels: [],
      title: 'Sandy beach',
      details: '',
      soundId: 'sandy-beach'
    },
    es: {
      labels: [],
      title: 'Playa arenosa',
      details: '',
      soundId: 'sandy-beach'
    },
    type: 'nature',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: true,
    imageId: 'sandy-beach'
  },
  'rainy-day': {
    en: {
      labels: [],
      title: `Rainy ${today}`,
      details: '',
      soundId: 'rainy-day'
    },
    es: {
      labels: [],
      title: `${hoy} lluvioso`,
      details: '',
      soundId: 'rainy-day'
    },
    type: 'nature',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: false,
    imageId: 'rainy-day'
  },
  'green-forest': {
    en: {
      labels: [],
      title: 'Green forest',
      details: '',
      soundId: 'green-forest'
    },
    es: {
      labels: [],
      title: 'Bosque verde',
      details: '',
      soundId: 'green-forest'
    },
    type: 'nature',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: false,
    imageId: 'green-forest'
  },
  'autumn-stream': {
    en: {
      labels: [],
      title: 'Autumn stream',
      details: '',
      soundId: 'autumn-stream'
    },
    es: {
      labels: [],
      title: 'Corriente de otoño',
      details: '',
      soundId: 'autumn-stream'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'autumn-stream'
  },
  'birds-in-a-rainforest': {
    en: {
      labels: [],
      title: 'Birds in a rainforest',
      details: '',
      soundId: 'birds-in-a-rainforest'
    },
    es: {
      labels: [],
      title: 'Aves en una selva lluviosa',
      details: '',
      soundId: 'birds-in-a-rainforest'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'birds-in-a-rainforest'
  },
  'breaking-waves': {
    en: {
      labels: [],
      title: 'Breaking waves',
      details: '',
      soundId: 'breaking-waves'
    },
    es: {
      labels: [],
      title: 'Olas rompientes',
      details: '',
      soundId: 'breaking-waves'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'breaking-waves'
  },
  'bubbles-everywhere': {
    en: {
      labels: [],
      title: 'Bubbles everywhere',
      details: '',
      soundId: 'bubbles-everywhere'
    },
    es: {
      labels: [],
      title: 'Burbujas por doquier',
      details: '',
      soundId: 'bubbles-everywhere'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'bubbles-everywhere'
  },
  'buzzing-fauna': {
    en: {
      labels: [],
      title: 'Buzzing fauna',
      details: '',
      soundId: 'buzzing-fauna'
    },
    es: {
      labels: [],
      title: 'Fauna zumbante',
      details: '',
      soundId: 'buzzing-fauna'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'buzzing-fauna'
  },
  'cave-thumb-drops': {
    en: {
      labels: [],
      title: 'Cave thumb drops',
      details: '',
      soundId: 'cave-thumb-drops'
    },
    es: {
      labels: [],
      title: 'Gotas en cueva de pulgar',
      details: '',
      soundId: 'cave-thumb-drops'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'cave-thumb-drops'
  },
  'tropical-forest-river': {
    en: {
      labels: [],
      title: 'Tropical forest river',
      details: '',
      soundId: 'tropical-forest-river'
    },
    es: {
      labels: [],
      title: 'Río en bosque tropical',
      details: '',
      soundId: 'tropical-forest-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'tropical-forest-river'
  },
  'wet-cave': {
    en: {
      labels: [],
      title: 'Wet cave',
      details: '',
      soundId: 'wet-cave'
    },
    es: {
      labels: [],
      title: 'Cueva húmeda',
      details: '',
      soundId: 'wet-cave'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'wet-cave'
  },
  'desert-morning': {
    en: {
      labels: [],
      title: 'Desert morning',
      details: '',
      soundId: 'desert-morning'
    },
    es: {
      labels: [],
      title: 'Mañana en el desierto',
      details: '',
      soundId: 'desert-morning'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'desert-morning'
  },
  'mountain-cave-opening': {
    en: {
      labels: [],
      title: 'Mountain cave opening',
      details: '',
      soundId: 'mountain-cave-opening'
    },
    es: {
      labels: [],
      title: 'Apertura de cueva montañosa',
      details: '',
      soundId: 'mountain-cave-opening'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mountain-cave-opening'
  },
  'frogs-in-the-mangrove': {
    en: {
      labels: [],
      title: 'Frogs in the mangrove',
      details: '',
      soundId: 'frogs-in-the-mangrove'
    },
    es: {
      labels: [],
      title: 'Ranas en el manglar',
      details: '',
      soundId: 'frogs-in-the-mangrove'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'frogs-in-the-mangrove'
  },
  'frogs-in-tune': {
    en: {
      labels: [],
      title: 'Frogs in tune',
      details: '',
      soundId: 'frogs-in-tune'
    },
    es: {
      labels: [],
      title: 'Ranas en sintonía',
      details: '',
      soundId: 'frogs-in-tune'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'frogs-in-tune'
  },
  'rocky-beach-waves': {
    en: {
      labels: [],
      title: 'Rocky beach waves',
      details: '',
      soundId: 'rocky-beach-waves'
    },
    es: {
      labels: [],
      title: 'Olas en playa rocosa',
      details: '',
      soundId: 'rocky-beach-waves'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'rocky-beach-waves'
  },
  'gentle-tropical-waves': {
    en: {
      labels: [],
      title: 'Gentle tropical waves',
      details: '',
      soundId: 'gentle-tropical-waves'
    },
    es: {
      labels: [],
      title: 'Olas tropicales suaves',
      details: '',
      soundId: 'gentle-tropical-waves'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'gentle-tropical-waves'
  },
  'hotspring-mud-bubbles': {
    en: {
      labels: [],
      title: 'Hotspring mud bubbles',
      details: '',
      soundId: 'hotspring-mud-bubbles'
    },
    es: {
      labels: [],
      title: 'Burbujas de lodo en aguas termales',
      details: '',
      soundId: 'hotspring-mud-bubbles'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'hotspring-mud-bubbles'
  },
  'hotspring-water-mud-bubbles': {
    en: {
      labels: [],
      title: 'Hotspring water mud bubbles',
      details: '',
      soundId: 'hotspring-water-mud-bubbles'
    },
    es: {
      labels: [],
      title: 'Burbujas de lodo y agua en aguas termales',
      details: '',
      soundId: 'hotspring-water-mud-bubbles'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'hotspring-water-mud-bubbles'
  },
  'jungle-cave': {
    en: {
      labels: [],
      title: 'Jungle cave',
      details: '',
      soundId: 'jungle-cave'
    },
    es: {
      labels: [],
      title: 'Cueva en la jungla',
      details: '',
      soundId: 'jungle-cave'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'jungle-cave'
  },
  'jungle-river': {
    en: {
      labels: [],
      title: 'Jungle river',
      details: '',
      soundId: 'jungle-river'
    },
    es: {
      labels: [],
      title: 'Río en la jungla',
      details: '',
      soundId: 'jungle-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'jungle-river'
  },
  'jungle-thunderstorm': {
    en: {
      labels: [],
      title: 'Jungle thunderstorm',
      details: '',
      soundId: 'jungle-thunderstorm'
    },
    es: {
      labels: [],
      title: 'Tormenta en la jungla',
      details: '',
      soundId: 'jungle-thunderstorm'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'jungle-thunderstorm'
  },
  'little-mud-bubbles': {
    en: {
      labels: [],
      title: 'Little mud bubbles',
      details: '',
      soundId: 'little-mud-bubbles'
    },
    es: {
      labels: [],
      title: 'Pequeñas burbujas de lodo',
      details: '',
      soundId: 'little-mud-bubbles'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'little-mud-bubbles'
  },
  'mountain-river': {
    en: {
      labels: [],
      title: 'Mountain river',
      details: '',
      soundId: 'mountain-river'
    },
    es: {
      labels: [],
      title: 'Río montañoso',
      details: '',
      soundId: 'mountain-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mountain-river'
  },
  'mountain-winds': {
    en: {
      labels: [],
      title: 'Mountain winds',
      details: '',
      soundId: 'mountain-winds'
    },
    es: {
      labels: [],
      title: 'Vientos montañosos',
      details: '',
      soundId: 'mountain-winds'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mountain-winds'
  },
  'mud-bubbles': {
    en: {
      labels: [],
      title: 'Mud bubbles',
      details: '',
      soundId: 'mud-bubbles'
    },
    es: {
      labels: [],
      title: 'Burbujas de lodo',
      details: '',
      soundId: 'mud-bubbles'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mud-bubbles'
  },
  'mysterious-mangrove': {
    en: {
      labels: [],
      title: 'Mysterious mangrove',
      details: '',
      soundId: 'mysterious-mangrove'
    },
    es: {
      labels: [],
      title: 'Manglar misterioso',
      details: '',
      soundId: 'mysterious-mangrove'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mysterious-mangrove'
  },
  'mystic-island': {
    en: {
      labels: [],
      title: 'Mystic island',
      details: '',
      soundId: 'mystic-island'
    },
    es: {
      labels: [],
      title: 'Isla mística',
      details: '',
      soundId: 'mystic-island'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mystic-island'
  },
  'mystical-winds': {
    en: {
      labels: [],
      title: 'Mystical winds',
      details: '',
      soundId: 'mystical-winds'
    },
    es: {
      labels: [],
      title: 'Vientos místicos',
      details: '',
      soundId: 'mystical-winds'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mystical-winds'
  },
  'on-the-river': {
    en: {
      labels: [],
      title: 'On the river',
      details: '',
      soundId: 'on-the-river'
    },
    es: {
      labels: [],
      title: 'En el río',
      details: '',
      soundId: 'on-the-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'on-the-river'
  },
  'tropical-waves': {
    en: {
      labels: [],
      title: 'Tropical waves',
      details: '',
      soundId: 'tropical-waves'
    },
    es: {
      labels: [],
      title: 'Olas tropicales',
      details: '',
      soundId: 'tropical-waves'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'tropical-waves'
  },
  'rain-and-birds': {
    en: {
      labels: [],
      title: 'Rain and birds',
      details: '',
      soundId: 'rain-and-birds'
    },
    es: {
      labels: [],
      title: 'Lluvia y pájaros',
      details: '',
      soundId: 'rain-and-birds'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'rain-and-birds'
  },
  'raindrops-and-bird-songs': {
    en: {
      labels: [],
      title: 'Raindrops and bird songs',
      details: '',
      soundId: 'raindrops-and-bird-songs'
    },
    es: {
      labels: [],
      title: 'Gotas de lluvia y cantos de pájaros',
      details: '',
      soundId: 'raindrops-and-bird-songs'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'raindrops-and-bird-songs'
  },
  'raindrops-and-rain': {
    en: {
      labels: [],
      title: 'Raindrops and rain',
      details: '',
      soundId: 'raindrops-and-rain'
    },
    es: {
      labels: [],
      title: 'Gotas de lluvia y lluvia',
      details: '',
      soundId: 'raindrops-and-rain'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'raindrops-and-rain'
  },
  'rainfall-drops': {
    en: {
      labels: [],
      title: 'Rainfall drops',
      details: '',
      soundId: 'rainfall-drops'
    },
    es: {
      labels: [],
      title: 'Gotas de lluvia',
      details: '',
      soundId: 'rainfall-drops'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'rainfall-drops'
  },
  'relaxed-in-the-rain': {
    en: {
      labels: [],
      title: 'Relaxed in the rain',
      details: '',
      soundId: 'relaxed-in-the-rain'
    },
    es: {
      labels: [],
      title: 'Relajado en la lluvia',
      details: '',
      soundId: 'relaxed-in-the-rain'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'relaxed-in-the-rain'
  },
  'river-in-a-cave': {
    en: {
      labels: [],
      title: 'River in a cave',
      details: '',
      soundId: 'river-in-a-cave'
    },
    es: {
      labels: [],
      title: 'Río en una cueva',
      details: '',
      soundId: 'river-in-a-cave'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'river-in-a-cave'
  },
  'river-passing-by': {
    en: {
      labels: [],
      title: 'River passing by',
      details: '',
      soundId: 'river-passing-by'
    },
    es: {
      labels: [],
      title: 'Río que pasa',
      details: '',
      soundId: 'river-passing-by'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'river-passing-by'
  },
  'lava-bubbles': {
    en: {
      labels: [],
      title: 'Lava bubbles',
      details: '',
      soundId: 'lava-bubbles'
    },
    es: {
      labels: [],
      title: 'Burbujas de lava',
      details: '',
      soundId: 'lava-bubbles'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'lava-bubbles'
  },
  'river-in-the-jungle': {
    en: {
      labels: [],
      title: 'River in the jungle',
      details: '',
      soundId: 'river-in-the-jungle'
    },
    es: {
      labels: [],
      title: 'Río en la jungla',
      details: '',
      soundId: 'river-in-the-jungle'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'river-in-the-jungle'
  },
  'soft-drops': {
    en: {
      labels: [],
      title: 'Soft drops',
      details: '',
      soundId: 'soft-drops'
    },
    es: {
      labels: [],
      title: 'Gotas suaves',
      details: '',
      soundId: 'soft-drops'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'soft-drops'
  },
  'storm-aftermath': {
    en: {
      labels: [],
      title: 'Storm aftermath',
      details: '',
      soundId: 'storm-aftermath'
    },
    es: {
      labels: [],
      title: 'Secuelas de la tormenta',
      details: '',
      soundId: 'storm-aftermath'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'storm-aftermath'
  },
  'tropical-rainforest-waterfall': {
    en: {
      labels: [],
      title: 'Tropical rainforest waterfall',
      details: '',
      soundId: 'tropical-rainforest-waterfall'
    },
    es: {
      labels: [],
      title: 'Cascada en bosque tropical',
      details: '',
      soundId: 'tropical-rainforest-waterfall'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'tropical-rainforest-waterfall'
  },
  'low-mountain-river': {
    en: {
      labels: [],
      title: 'Low mountain river',
      details: '',
      soundId: 'low-mountain-river'
    },
    es: {
      labels: [],
      title: 'Río de montaña baja',
      details: '',
      soundId: 'low-mountain-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'low-mountain-river'
  },
  'water-drops-in-open-cave': {
    en: {
      labels: [],
      title: 'Water drops in open cave',
      details: '',
      soundId: 'water-drops-in-open-cave'
    },
    es: {
      labels: [],
      title: 'Gotas de agua en cueva abierta',
      details: '',
      soundId: 'water-drops-in-open-cave'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'water-drops-in-open-cave'
  },
  'water-in-a-cave': {
    en: {
      labels: [],
      title: 'Water in a cave',
      details: '',
      soundId: 'water-in-a-cave'
    },
    es: {
      labels: [],
      title: 'Agua en una cueva',
      details: '',
      soundId: 'water-in-a-cave'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'water-in-a-cave'
  },
  'river-stream': {
    en: {
      labels: [],
      title: 'River stream',
      details: '',
      soundId: 'river-stream'
    },
    es: {
      labels: [],
      title: 'Corriente de agua',
      details: '',
      soundId: 'river-stream'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'river-stream'
  },
  'waves-in-motion': {
    en: {
      labels: [],
      title: 'Waves in motion',
      details: '',
      soundId: 'waves-in-motion'
    },
    es: {
      labels: [],
      title: 'Olas en movimiento',
      details: '',
      soundId: 'waves-in-motion'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'waves-in-motion'
  },
  'quiet-beach': {
    en: {
      labels: [],
      title: 'Quiet beach',
      details: '',
      soundId: 'quiet-beach'
    },
    es: {
      labels: [],
      title: 'Playa tranquila',
      details: '',
      soundId: 'quiet-beach'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'quiet-beach'
  },
  'wild-river': {
    en: {
      labels: [],
      title: 'Wild river',
      details: '',
      soundId: 'wild-river'
    },
    es: {
      labels: [],
      title: 'Río salvaje',
      details: '',
      soundId: 'wild-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'wild-river'
  },
  'wonders-of-the-rainforest': {
    en: {
      labels: [],
      title: 'Wonders of the rainforest',
      details: '',
      soundId: 'wonders-of-the-rainforest'
    },
    es: {
      labels: [],
      title: 'Maravillas del bosque tropical',
      details: '',
      soundId: 'wonders-of-the-rainforest'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'wonders-of-the-rainforest'
  },
  'clear-water-birds': {
    en: {
      labels: [],
      title: 'Clear water & birds',
      details: '',
      soundId: 'clear-water-birds'
    },
    es: {
      labels: [],
      title: 'Agua clara y pájaros',
      details: '',
      soundId: 'clear-water-birds'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'clear-water-birds'
  },
  'powerful-tropical-ocean': {
    en: {
      labels: [],
      title: 'Powerful tropical ocean',
      details: '',
      soundId: 'powerful-tropical-ocean'
    },
    es: {
      labels: [],
      title: 'Poderoso océano tropical',
      details: '',
      soundId: 'powerful-tropical-ocean'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'powerful-tropical-ocean'
  },
  'small-jungle-river': {
    en: {
      labels: [],
      title: 'Small jungle river',
      details: '',
      soundId: 'small-jungle-river'
    },
    es: {
      labels: [],
      title: 'Pequeño río selvático',
      details: '',
      soundId: 'small-jungle-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'small-jungle-river'
  },
  'thunderstorm-fades-out': {
    en: {
      labels: [],
      title: 'Thunderstorm fades out',
      details: '',
      soundId: 'thunderstorm-fades-out'
    },
    es: {
      labels: [],
      title: 'Tormenta se desvanece',
      details: '',
      soundId: 'thunderstorm-fades-out'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'thunderstorm-fades-out'
  },
  'forest-river': {
    en: {
      labels: [],
      title: 'Forest river',
      details: '',
      soundId: 'forest-river'
    },
    es: {
      labels: [],
      title: 'Río del bosque',
      details: '',
      soundId: 'forest-river'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'forest-river'
  },
  'jungle-river-birds': {
    en: {
      labels: [],
      title: 'Jungle river birds',
      details: '',
      soundId: 'jungle-river-birds'
    },
    es: {
      labels: [],
      title: 'Río selvático con pájaros',
      details: '',
      soundId: 'jungle-river-birds'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'jungle-river-birds'
  },
  'lagoon-beach': {
    en: {
      labels: [],
      title: 'Lagoon beach',
      details: '',
      soundId: 'lagoon-beach'
    },
    es: {
      labels: [],
      title: 'Playa de la laguna',
      details: '',
      soundId: 'lagoon-beach'
    },
    type: 'nature',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'lagoon-beach'
  }
}
