import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { watch } from 'vue'
import Main from '../views/Main.vue'
import { useAuth } from '@/hooks/useAuth'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/do-nothing',
    name: 'do-nothing',
    component: () =>
      import(/* webpackChunkName: "do-nothing" */ '../views/DoNothing.vue'),
    meta: { requiresAuth: true } // Protected route
  },
  {
    path: '/just-breathe',
    name: 'just-breathe',
    component: () =>
      import(/* webpackChunkName: "just-breathe" */ '../views/JustBreathe.vue'),
    meta: { requiresAuth: true } // Protected route
  },
  {
    path: '/wise-words',
    name: 'wise-words',
    component: () =>
      import(/* webpackChunkName: "wise-words" */ '../views/WiseWords.vue'),
    meta: { requiresAuth: true } // Protected route
  },
  {
    path: '/account',
    name: 'account',
    component: () =>
      import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: { requiresAuth: true } // Protected route
  },
  {
    path: '/stats',
    name: 'stats',
    component: () =>
      import(/* webpackChunkName: "stats" */ '../views/Stats.vue'),
    meta: { requiresAuth: true } // Protected route
  },
  {
    path: '/awards',
    name: 'awards',
    component: () =>
      import(/* webpackChunkName: "awards" */ '../views/Awards.vue'),
    meta: { requiresAuth: true } // Protected route
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
    beforeEnter: (_to, _from, next) => {
      const { isAuthenticated } = useAuth()
      if (isAuthenticated.value) {
        next({ name: 'main' }) // Redirect authenticated users to main
      } else {
        next() // Allow access for unauthenticated users
      }
    },
    children: [
      {
        path: 'login',
        name: 'AuthLogin',
        component: () =>
          import(
            /* webpackChunkName: "auth-login" */ '../components/AuthLogin.vue'
          )
      },
      {
        path: 'sign-up',
        name: 'AuthSignUp',
        component: () =>
          import(
            /* webpackChunkName: "auth-sign-up" */ '../components/AuthSignUp.vue'
          )
      },
      {
        path: 'verify-email',
        name: 'AuthEmailVerify',
        component: () =>
          import(
            /* webpackChunkName: "auth-verify-email" */ '../components/AuthEmailVerify.vue'
          )
      },
      {
        path: 'forgot-password',
        name: 'AuthForgotPassword',
        component: () =>
          import(
            /* webpackChunkName: "auth-forgot-password" */ '../components/AuthForgotPassword.vue'
          )
      },
      {
        path: 'reset-password',
        name: 'AuthResetPassword',
        component: () =>
          import(
            /* webpackChunkName: "auth-reset-password" */ '../components/AuthResetPassword.vue'
          )
      }
    ]
  },
  { path: '/sign-up', redirect: '/auth/sign-up' },
  { path: '/login', redirect: '/auth/login' },
  { path: '/verify-email', redirect: '/auth/verify-email' },
  { path: '/forgot-password', redirect: '/auth/forgot-password' },
  { path: '/reset-password', redirect: '/auth/reset-password' },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'main' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Global guard to handle authentication and loading
router.beforeEach(async (to, _from, next) => {
  const { isAuthenticated, authReady } = useAuth()

  // Wait for authReady if necessary
  if (!authReady.value) {
    await new Promise<void>((resolve) => {
      const stopWatch = watch(
        authReady,
        (ready) => {
          if (ready) {
            stopWatch() // Stop watching once auth is ready
            resolve()
          }
        },
        { immediate: true }
      )
    })
  }

  // Check if the route requires authentication
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated.value
  ) {
    next({ name: 'AuthLogin' }) // Redirect to login if not authenticated
  } else {
    next() // Continue navigation
  }
})

export default router
