import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from '@/App.vue'
import router from '@/router'

// Global components
import Text from '@/components/Text.vue'
import Icon from '@/components/Icon.vue'
import Logo from '@/components/Logo.vue'
import Loader from '@/components/Loader.vue'
import Card from '@/components/Card.vue'
import Prices from '@/components/Prices.vue'
import Switch from '@/components/Switch.vue'
import Collapsible from '@/components/Collapsible.vue'
import Spinner from '@/components/Spinner.vue'
import AuthSocials from '@/components/AuthSocials.vue'
import Toast from '@/components/Toast.vue'
import Modal from '@/components/Modal.vue'

// Auth
import { useAuth } from '@/hooks/useAuth'
import { UserInjectionKey } from '@/injectionKeys'

// Services
import { i18n } from '@/services/i18n'

const { currentUser } = useAuth()
const pinia = createPinia()
const app = createApp(App)

app
  .use(pinia)
  .use(router)
  .use(i18n)

  // Note: Symbols as injection keys should be defined outside and imported where needed.
  // This ensures the same reference is used across your application.
  // Define this in a separate file, e.g., injectionKeys.ts
  .provide(UserInjectionKey, currentUser)

  .component('Text', Text)
  .component('Icon', Icon)
  .component('Logo', Logo)
  .component('Loader', Loader)
  .component('Card', Card)
  .component('Prices', Prices)
  .component('Switch', Switch)
  .component('Collapsible', Collapsible)
  .component('Spinner', Spinner)
  .component('AuthSocials', AuthSocials)
  .component('Toast', Toast)
  .component('Modal', Modal)
  .mount('#app')
