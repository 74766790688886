<template>
  <aside
    :class="[
      'sidebar',
      { collapsed: router.currentRoute.value.query.sidebar === 'collapsed' }
    ]"
  >
    <header class="sidebar__header">
      <Logo
        monogram
        v-if="router.currentRoute.value.query.sidebar === 'collapsed'"
      />
      <Logo v-else />
    </header>

    <section class="sidebar__main">
      <ul>
        <li class="sidebar__list-header">
          <Text is="p3">{{ t('sidebar.sections[0].title') }}</Text>
        </li>
        <li :class="[{ active: currentFilter === 'shuffle' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'shuffle'
                }
              })
            "
          >
            <Icon icon="collection-shuffle" />
            <Text>{{ t('sidebar.sections[0].tabs.shuffle') }}</Text>
          </button>
        </li>
        <li :class="[{ active: currentFilter === 'nature' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'nature'
                }
              })
            "
          >
            <Icon icon="collection-nature" />
            <Text>{{ t('sidebar.sections[0].tabs.nature') }}</Text>
            <Spinner v-if="isPlayingNature && store.isPlaying" type="bars" />
            <Spinner v-if="isPlayingNature && !store.isPlaying" type="stop" />
          </button>
        </li>
        <li :class="[{ active: currentFilter === 'urban' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'urban'
                }
              })
            "
          >
            <Icon icon="collection-urban" />
            <Text>{{ t('sidebar.sections[0].tabs.urban') }}</Text>
            <Spinner v-if="isPlayingUrban && store.isPlaying" type="bars" />
            <Spinner v-if="isPlayingUrban && !store.isPlaying" type="stop" />
          </button>
        </li>
        <li :class="[{ active: currentFilter === 'music' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'music'
                }
              })
            "
          >
            <Icon icon="collection-music" />
            <Text>{{ t('sidebar.sections[0].tabs.music') }}</Text>
            <Spinner v-if="isPlayingMusic && store.isPlaying" type="bars" />
            <Spinner v-if="isPlayingMusic && !store.isPlaying" type="stop" />
          </button>
        </li>
        <li :class="[{ active: currentFilter === 'meditation' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'meditation'
                }
              })
            "
          >
            <Icon icon="collection-meditation" />
            <Text>{{ t('sidebar.sections[0].tabs.meditations') }}</Text>
            <Spinner
              v-if="isPlayingMeditation && store.isPlaying"
              type="bars"
            />
            <Spinner
              v-if="isPlayingMeditation && !store.isPlaying"
              type="stop"
            />
          </button>
        </li>
        <li :class="[{ active: currentFilter === 'session' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'session'
                }
              })
            "
          >
            <Icon icon="collection-session" />
            <Text>{{ t('sidebar.sections[0].tabs.sessions') }}</Text>
            <Spinner v-if="isPlayingSession && store.isPlaying" type="bars" />
            <Spinner v-if="isPlayingSession && !store.isPlaying" type="stop" />
          </button>
        </li>
        <li :class="[{ active: currentFilter === 'favorites' }]">
          <button
            @click="
              router.push({
                name: 'main',
                query: {
                  ...router.currentRoute.value.query,
                  filter: 'favorites'
                }
              })
            "
          >
            <Icon icon="collection-favorite" />
            <Text>{{ t('sidebar.sections[0].tabs.favorites') }}</Text>
          </button>
        </li>
      </ul>

      <ul>
        <li class="sidebar__list-header">
          <Text is="p3">{{ t('sidebar.sections[1].title') }}</Text>
        </li>

        <li :class="[{ active: route.name === 'do-nothing' }]">
          <button
            @click="
              router.push({
                name: 'do-nothing',
                query: {
                  ...router.currentRoute.value.query,
                  filter: undefined
                }
              })
            "
          >
            <Icon icon="feature-do-nothing" />
            <Text>{{ t('sidebar.sections[1].tabs.doNothing') }}</Text>
          </button>
        </li>

        <li :class="[{ active: route.name === 'just-breathe' }]">
          <button
            @click="
              router.push({
                name: 'just-breathe',
                query: {
                  ...router.currentRoute.value.query,
                  filter: undefined
                }
              })
            "
          >
            <Icon icon="feature-just-breathe" />
            <Text>{{ t('sidebar.sections[1].tabs.justBreathe') }}</Text>
          </button>
        </li>

        <li :class="[{ active: route.name === 'wise-words' }]">
          <button
            @click="
              router.push({
                name: 'wise-words',
                query: {
                  ...router.currentRoute.value.query,
                  filter: undefined
                }
              })
            "
          >
            <Icon icon="feature-wise-words" />
            <Text>{{ t('sidebar.sections[1].tabs.wiseWords') }}</Text>
          </button>
        </li>
      </ul>

      <ul>
        <li class="sidebar__list-header">
          <Text is="p3">{{ t('sidebar.sections[2].title') }}</Text>
        </li>
        <li :class="[{ active: route.name === 'account' }]">
          <button
            @click="
              router.push({
                name: 'account',
                query: {
                  ...router.currentRoute.value.query,
                  filter: undefined
                }
              })
            "
          >
            <Icon icon="settings-account" />
            <Text>{{ t('sidebar.sections[2].tabs.account') }}</Text>
          </button>
        </li>
        <li :class="[{ active: route.name === 'stats' }]">
          <button
            @click="
              router.push({
                name: 'stats',
                query: {
                  ...router.currentRoute.value.query,
                  filter: undefined
                }
              })
            "
          >
            <Icon icon="settings-stats" />
            <Text>{{ t('sidebar.sections[2].tabs.stats') }}</Text>
          </button>
        </li>
        <li :class="[{ active: route.name === 'awards' }]">
          <button
            @click="
              router.push({
                name: 'awards',
                query: {
                  ...router.currentRoute.value.query,
                  filter: undefined
                }
              })
            "
          >
            <Icon icon="settings-awards" />
            <Text>{{ t('sidebar.sections[2].tabs.awards') }}</Text>
          </button>
        </li>

        <li>
          <button @click="toggleDark()">
            <Icon icon="settings-light-dark" />

            <Text
              >{{
                isDark
                  ? t('sidebar.sections[2].tabs.mode.dark')
                  : t('sidebar.sections[2].tabs.mode.light')
              }}
            </Text>
          </button>
        </li>
      </ul>
    </section>

    <footer class="sidebar__footer">
      <ul>
        <li>
          <button
            v-if="sidebarState === 'collapsed'"
            @click="updateRoute('open')"
          >
            <Icon icon="chevron-right" />
          </button>

          <button v-else @click="updateRoute('collapsed')">
            <Icon icon="chevron-left" />
            <Text>{{ t('sidebar.sections[3].tabs.collapse') }}</Text>
          </button>
        </li>
      </ul>
    </footer>
  </aside>
</template>

<script lang="ts" setup>
import { ref, computed, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useMainStore } from '@/state/index'
import { useDark, useToggle } from '@vueuse/core'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'SidebarComponent'
})

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const store = useMainStore()
const isDark = useDark()
const toggleDark = useToggle(isDark)

const currentFilter = ref(router.currentRoute.value.query.filter)
const sidebarState = ref(router.currentRoute.value.query.sidebar)

watchEffect(() => {
  currentFilter.value = router.currentRoute.value.query.filter
  sidebarState.value = router.currentRoute.value.query.sidebar
})

const updateRoute = (sidebarStatus: 'open' | 'collapsed') => {
  const currentName = route.name ?? 'main'
  router.push({
    name: currentName,
    query: {
      ...route.query,
      sidebar: sidebarStatus
    }
  })
}

const isPlayingNature = computed(() => store.playingSound?.type === 'nature')
const isPlayingUrban = computed(() => store.playingSound?.type === 'urban')
const isPlayingMusic = computed(() => store.playingSound?.type === 'music')
const isPlayingSession = computed(() => store.playingSound?.type === 'session')
const isPlayingMeditation = computed(
  () => store.playingSound?.type === 'meditation'
)
</script>

<style scoped lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-neutral-80);
  width: var(--size-sidebar-width);
  height: 100svh;
  overflow-y: scroll;

  :-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  // li {
  //   border-bottom: var(--size-1) solid var(--color-neutral-70);
  // }

  &.collapsed {
    width: var(--size-180);

    li {
      .text {
        display: none;
      }

      button {
        padding: 0 var(--size-50);
      }
    }
  }
}

.sidebar__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  margin-bottom: var(--size-20);
  padding: var(--size-40) var(--size-40) var(--size-30);
  border-bottom: var(--size-1) solid var(--color-neutral-70);
  background-color: inherit;
  z-index: var(--z-index-medium);
}

.sidebar__footer {
  position: sticky;
  bottom: 0;

  ul {
    margin: 0;
  }

  li {
    &:last-of-type {
      border-bottom: 0;
    }

    button {
      height: var(--size-player-height);
      border-bottom: none;
      border-top: var(--size-1) solid var(--color-neutral-70);
    }
  }
}

.sidebar__main {
  flex: 1;

  ul {
    &:last-of-type {
      margin-bottom: var(--size-40);
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: var(--size-20) 0 0 0;
}

li {
  font-size: var(--font-size-p2);

  button {
    display: flex;
    align-items: center;
    position: relative;
    height: var(--size-110);
    padding: 0 var(--size-80);
    column-gap: var(--size-30);
    background-color: var(--color-neutral-80);
    border: 0;
    border-left: var(--size-1) solid transparent;
    cursor: pointer;
    width: 100%;
    color: inherit;
    outline: 0;

    .spinner {
      position: absolute;
      right: var(--size-40);
    }
  }

  &:hover {
    button {
      background-color: var(--color-neutral-70);
    }
  }

  &.active,
  &:active {
    outline: 0;

    &:hover,
    button {
      background-color: var(--color-body-bg);
    }

    button {
      border-left: var(--size-1) solid var(--color-accent-50);
    }
  }
}

.sidebar__list-header {
  display: flex;
  align-items: center;
  height: var(--size-80);
  padding: 0 var(--size-40);
  color: var(--color-neutral-30);
}
</style>
