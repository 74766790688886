<template>
  <main class="prices">
    <section v-if="isLoading">
      <Spinner :size="80" />
    </section>

    <section v-if="isStripeLoading">
      <br />
      <Text>
        {{ t('prices.loader.message') }}
      </Text>
      <br />
      <Spinner :size="80" />
    </section>

    <section v-else>
      <form v-if="!store.userHasSubscription">
        <div v-if="availablePrices.length">
          <ul
            v-for="product in availablePrices"
            :key="product.id"
            class="prices__prices"
          >
            <li
              v-for="price in product.prices"
              :key="price.priceId"
              class="price__price"
            >
              <label
                :class="[
                  'prices__price-label',
                  {
                    'prices__price-label--selected':
                      selectedPrice === price.priceId
                  }
                ]"
              >
                <span
                  v-if="price.interval === 'year'"
                  class="prices__price-best-value-tag"
                >
                  <Text is="h6" black>{{ t('prices.bestValue') }}</Text>
                </span>

                <div class="prices__price-details">
                  <Text>
                    {{
                      price.interval === 'year'
                        ? t('prices.interval.yearly')
                        : t('prices.interval.monthly')
                    }}
                  </Text>

                  <Text is="h3">
                    {{ t('prices.daysFree') }}
                  </Text>

                  <Text is="p1" inline>
                    {{
                      formatPrice(
                        price.unit_amount /
                          (price.interval === 'year' ? 12 : 1),
                        price.currency
                      )
                    }}
                    <Text>{{ t('prices.perMonth') }}</Text>
                  </Text>

                  <Text v-if="price.interval === 'year'" is="p3">
                    {{
                      t('prices.billedAnnually', {
                        amount: formatPrice(price.unit_amount, price.currency)
                      })
                    }}
                  </Text>
                </div>

                <input
                  type="radio"
                  :value="price.priceId"
                  @change="selectedInterval = price.interval"
                  v-model="selectedPrice"
                  class="prices__price-radio"
                />
                <span class="prices__price-radio-checkmark"></span>
              </label>
            </li>
          </ul>

          <!-- Voucher Input Field -->
          <div class="prices__voucher-container">
            <input
              ref="voucherInput"
              v-model="voucherCode"
              type="text"
              :placeholder="t('prices.enterVoucher')"
              class="prices__voucher-input"
            />
            <Text v-if="voucherError" class="prices__voucher-error">
              {{ voucherError }}
            </Text>
          </div>

          <!-- Display Try Now Button below the list -->
          <section class="prices__checkout-container">
            <button
              class="prices__checkout-button"
              @click.prevent="handleSubmit(selectedPrice)"
              :disabled="!selectedPrice || isLoading"
            >
              <Text is="h5">
                {{ t('prices.tryForFree', { companyName: t('company.name') }) }}
              </Text>
            </button>

            <!-- Show subscription details based on the selected interval -->
            <Text is="p3">
              {{
                t('prices.freeTrialMessage', {
                  days: 7,
                  price:
                    selectedInterval === 'year'
                      ? formatPrice(
                          availablePrices[0].prices.find(
                            (p) => p.interval === 'year'
                          )?.unit_amount || 0,
                          availablePrices[0].prices.find(
                            (p) => p.interval === 'year'
                          )?.currency || 'EUR'
                        )
                      : formatPrice(
                          availablePrices[0].prices.find(
                            (p) => p.interval === 'month'
                          )?.unit_amount || 0,
                          availablePrices[0].prices.find(
                            (p) => p.interval === 'month'
                          )?.currency || 'EUR'
                        ),
                  interval: intervalTranslation
                })
              }}
            </Text>
          </section>

          <br />
          <Text is="h5" centered>
            <Icon icon="minus" :size="40" />
            {{ t('auth.social.or') }}
            <Icon icon="minus" :size="40" />
          </Text>

          <button class="auth-button" @click="handleSkipSubscription">
            {{ t('prices.skipSubscription') }}
          </button>
        </div>

        <!-- Show Loader if no prices are available -->
        <Loader v-else />
      </form>

      <form v-else>
        <!-- Membership Status and Update Button -->
        <div class="prices__membership-status">
          <Text>{{ t('prices.activeSubscription') }}</Text>
          <br />
          <button
            class="prices__membership-update-button"
            @click.prevent="openCustomerPortal"
            :disabled="!store.userHasSubscription"
          >
            <Text is="h5">{{ t('prices.updateSubscription') }}</Text>
          </button>
        </div>
      </form>
    </section>
  </main>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, computed, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { createCheckoutSession, fetchUserSubscriptions } from '@/hooks/useDB'
import { formatPrice } from '@/utils/functions'
import { useUser } from '@/hooks/useUser'
import { useMainStore } from '@/state/index'
import { functions } from '@/services/firebase'
import { httpsCallable } from 'firebase/functions'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'PricesComponent'
})

const { t } = useI18n()
const store = useMainStore()
const { currentUser } = useUser()
const route = useRoute() // Access the route to capture the query parameter
const router = useRouter()
const isLoading = ref(true)
const isStripeLoading = ref(false)
const selectedPrice = ref<string>('')
const selectedInterval = ref<string>('')
const voucherError = ref<string>('') // Voucher error message

const handleSkipSubscription = () => {
  store.setSkippedSubscription(true)
  return router.push({ name: 'main' })
}

// Voucher code input, initialized from query parameter if available
const voucherCode = ref<string>((route.query.voucher as string) || '')

// Fetch the available prices from the store
const availablePrices = computed(() => store.availablePrices)

const intervalTranslation = computed(() => {
  return selectedInterval.value === 'year'
    ? t('prices.interval.yearly')
    : t('prices.interval.monthly')
})

// Watch the current user to fetch subscription status when they log in
watch(
  () => currentUser.value,
  async (newUser) => {
    if (newUser) {
      const hasSubscription = await fetchUserSubscriptions(newUser.uid)
      store.setUserHasSubscription(hasSubscription)
    }
  },
  { immediate: true }
)

// Preselect the user's active subscription if available, otherwise select yearly as default
onMounted(async () => {
  isLoading.value = true

  const hasSubscription = await fetchUserSubscriptions(
    currentUser.value?.uid as string
  )
  store.setUserHasSubscription(hasSubscription)

  // Wait until prices are potentially available
  await nextTick()

  if (hasSubscription) {
    isLoading.value = false
  } else if (availablePrices.value.length) {
    const activeSubscriptionPriceId = store.userSubscriptionDetails?.priceId

    if (activeSubscriptionPriceId) {
      selectedPrice.value = activeSubscriptionPriceId
      const activePrice = availablePrices.value
        .flatMap((product) => product.prices)
        .find((price) => price.priceId === activeSubscriptionPriceId)

      if (activePrice) {
        selectedInterval.value = activePrice.interval
      }
    } else {
      const yearlyPrice = availablePrices.value
        .flatMap((product) => product.prices)
        .find((price) => price.interval === 'year')

      if (yearlyPrice) {
        selectedPrice.value = yearlyPrice.priceId
        selectedInterval.value = 'year'
      }
    }
    isLoading.value = false
  } else {
    // Final check before showing toast to ensure prices are still empty
    if (!availablePrices.value.length) {
      isLoading.value = false
      store.setGlobalToast({
        visible: true,
        title: t('errors.noPricesTitle'),
        message: t('errors.noPricesMessage'),
        type: 'warning'
      })
    }
  }
})

// Handle form submission
const handleSubmit = async (priceId: string) => {
  voucherError.value = '' // Clear previous voucher errors

  if (!currentUser.value) {
    store.setGlobalToast({
      visible: true,
      title: t('errors.loginRequiredTitle'),
      message: t('errors.loginRequiredMessage'),
      type: 'warning'
    })
    return
  }

  if (!priceId) {
    store.setGlobalToast({
      visible: true,
      title: t('errors.membershipSelectionTitle'),
      message: t('prices.membershipSelection'),
      type: 'warning'
    })
    return
  }

  const trialPeriodDays = 7
  isStripeLoading.value = true

  try {
    const prefixedVoucherCode = voucherCode.value
      ? `promo_${voucherCode.value}`
      : ''
    const { success, errorMessage: sessionError } = await createCheckoutSession(
      currentUser.value.uid,
      priceId,
      trialPeriodDays,
      prefixedVoucherCode
    )

    if (!success) {
      if (sessionError) {
        // Remove "promo_" prefix from the sessionError, regardless of language
        voucherError.value = sessionError.replace('promo_', '')
        store.setGlobalToast({
          visible: true,
          title: t('errors.voucherErrorTitle'),
          message: voucherError.value,
          type: 'warning'
        })

        voucherCode.value = '' // Clear the voucher input
        setTimeout(() =>
          (
            document.querySelector('.prices__voucher-input') as HTMLInputElement
          )?.focus()
        )
      }

      isStripeLoading.value = false
      return
    }

    console.log('Checkout session created successfully.')
  } catch (error) {
    store.setGlobalToast({
      visible: true,
      title: t('errors.unexpectedErrorTitle'),
      message: t('errors.unexpectedErrorMessage'),
      type: 'warning'
    })

    isStripeLoading.value = false
  }
}

interface PortalLinkResponse {
  url?: string
}

const openCustomerPortal = async () => {
  isStripeLoading.value = true

  try {
    const createPortalLink = httpsCallable<object, PortalLinkResponse>(
      functions,
      process.env.NODE_ENV === 'production'
        ? 'ext-firestore-stripe-payments-createPortalLink'
        : 'ext-firestore-stripe-payments-invertase-createPortalLink'
    )

    const result = await createPortalLink({
      returnUrl: window.location.origin
    })

    if (result?.data?.url) {
      window.location.assign(result.data.url)
    } else {
      store.setGlobalToast({
        visible: true,
        title: t('errors.portalErrorTitle'),
        message: t('errors.portalErrorMessage'),
        type: 'warning'
      })
      isStripeLoading.value = false
    }
  } catch (error) {
    store.setGlobalToast({
      visible: true,
      title: t('errors.portalErrorTitle'),
      message: t('errors.portalErrorMessage'),
      type: 'warning'
    })
    isStripeLoading.value = false
  }
}
</script>

<style scoped lang="scss">
.prices {
}

.prices__prices {
  list-style: none;
  padding: 0;
}

.price__price {
  position: relative;
  margin-bottom: var(--size-60);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.prices__price-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  border: var(--size-1) solid var(--color-neutral-70);
  background-color: var(--color-neutral-80);
  border-radius: var(--size-30);
  padding: var(--size-40);
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-accent-50);
  }

  &--selected {
    background-color: var(--color-accent-50-32);
    border-color: var(--color-accent-50);

    .prices__price-radio {
      display: block;

      &-checkmark {
        display: block;
      }
    }

    &:hover {
      border-color: var(--color-body-text);
    }
  }
}

.prices__price-best-value-tag {
  display: inline-flex;
  text-align: left;
  color: var(--color-neutral-90);
  background-color: var(--color-accent-50);
  border-radius: 0;
  padding: var(--size-20) var(--size-100);
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(45deg) translate(var(--size-120), -20px);
}

.prices__price-details {
}

.prices__price-radio {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;

  &:checked ~ .prices__price-radio-checkmark {
    background-color: var(--color-accent-50);
  }

  &-checkmark {
    display: none;
    height: var(--size-70);
    width: var(--size-70);
    background-color: var(--color-neutral-30);
    border-radius: var(--size-radius-round);
  }
}

.prices__checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--size-60);
  color: var(--color-neutral-30);
}

.prices__checkout-button,
.prices__membership-update-button {
  padding: var(--size-40) var(--size-80);
  border: 0;
  outline: 0;
  margin-top: var(--size-40);
  border-radius: var(--size-160);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: var(--color-body-text);
  color: var(--color-neutral-90);

  &:hover {
    background-color: var(--color-accent-50);
  }
}

.prices__voucher-container {
  margin-top: var(--size-60);
}

.prices__voucher-input {
  height: var(--size-140);
  width: 100%;
  border-radius: var(--size-140);
  padding: 0 var(--size-60);
  background-color: transparent;
  border: var(--size-1) solid var(--color-neutral-30);
  color: var(--color-body-text);
  font-size: var(--font-size-p2);
  margin: var(--size-20) 0;
}

.prices__voucher-error {
  color: var(--color-warning-50);
  margin: var(--size-20) 0;
}

.auth-button {
  display: flex;
  background-color: transparent;
  color: var(--color-body-text);
  padding: var(--size-30) 0;
  border: 0;
  outline: 0;
  border-bottom: var(--size-5) solid var(--color-neutral-10);
  margin: var(--size-20) auto 0;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-accent-50);
    color: var(--color-body-text);
  }
}
</style>
