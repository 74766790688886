import { useI18n } from 'vue-i18n'

export function useError() {
  const { t } = useI18n()

  /**
   * Maps Firebase error codes to custom error messages using i18n for translation.
   * @param firebaseErrorCode - The error code from Firebase.
   * @returns The translated custom error message.
   */
  const getFirebaseErrorMessage = (firebaseErrorCode: string): string => {
    switch (firebaseErrorCode) {
      case 'auth/claims-too-large':
        return t('errors.auth.claimsTooLarge')
      case 'auth/email-already-exists':
        return t('errors.auth.emailAlreadyExists')
      case 'auth/id-token-expired':
        return t('errors.auth.idTokenExpired')
      case 'auth/id-token-revoked':
        return t('errors.auth.idTokenRevoked')
      case 'auth/insufficient-permission':
        return t('errors.auth.insufficientPermission')
      case 'auth/internal-error':
        return t('errors.auth.internalError')
      case 'auth/invalid-argument':
        return t('errors.auth.invalidArgument')
      case 'auth/invalid-claims':
        return t('errors.auth.invalidClaims')
      case 'auth/invalid-continue-uri':
        return t('errors.auth.invalidContinueUri')
      case 'auth/invalid-creation-time':
        return t('errors.auth.invalidCreationTime')
      case 'auth/invalid-credential':
        return t('errors.auth.invalidCredential')
      case 'auth/invalid-disabled-field':
        return t('errors.auth.invalidDisabledField')
      case 'auth/invalid-display-name':
        return t('errors.auth.invalidDisplayName')
      case 'auth/invalid-dynamic-link-domain':
        return t('errors.auth.invalidDynamicLinkDomain')
      case 'auth/invalid-email':
        return t('errors.auth.invalidEmail')
      case 'auth/invalid-email-verified':
        return t('errors.auth.invalidEmailVerified')
      case 'auth/invalid-hash-algorithm':
        return t('errors.auth.invalidHashAlgorithm')
      case 'auth/invalid-hash-block-size':
        return t('errors.auth.invalidHashBlockSize')
      case 'auth/invalid-hash-derived-key-length':
        return t('errors.auth.invalidHashDerivedKeyLength')
      case 'auth/invalid-hash-key':
        return t('errors.auth.invalidHashKey')
      case 'auth/invalid-hash-memory-cost':
        return t('errors.auth.invalidHashMemoryCost')
      case 'auth/invalid-hash-parallelization':
        return t('errors.auth.invalidHashParallelization')
      case 'auth/invalid-hash-rounds':
        return t('errors.auth.invalidHashRounds')
      case 'auth/invalid-hash-salt-separator':
        return t('errors.auth.invalidHashSaltSeparator')
      case 'auth/invalid-id-token':
        return t('errors.auth.invalidIdToken')
      case 'auth/invalid-last-sign-in-time':
        return t('errors.auth.invalidLastSignInTime')
      case 'auth/invalid-page-token':
        return t('errors.auth.invalidPageToken')
      case 'auth/invalid-password':
        return t('errors.auth.invalidPassword')
      case 'auth/invalid-password-hash':
        return t('errors.auth.invalidPasswordHash')
      case 'auth/invalid-password-salt':
        return t('errors.auth.invalidPasswordSalt')
      case 'auth/invalid-phone-number':
        return t('errors.auth.invalidPhoneNumber')
      case 'auth/invalid-photo-url':
        return t('errors.auth.invalidPhotoUrl')
      case 'auth/invalid-provider-data':
        return t('errors.auth.invalidProviderData')
      case 'auth/invalid-provider-id':
        return t('errors.auth.invalidProviderId')
      case 'auth/invalid-oauth-responsetype':
        return t('errors.auth.invalidOauthResponseType')
      case 'auth/invalid-session-cookie-duration':
        return t('errors.auth.invalidSessionCookieDuration')
      case 'auth/invalid-uid':
        return t('errors.auth.invalidUid')
      case 'auth/invalid-user-import':
        return t('errors.auth.invalidUserImport')
      case 'auth/maximum-user-count-exceeded':
        return t('errors.auth.maximumUserCountExceeded')
      case 'auth/missing-android-pkg-name':
        return t('errors.auth.missingAndroidPkgName')
      case 'auth/missing-continue-uri':
        return t('errors.auth.missingContinueUri')
      case 'auth/missing-hash-algorithm':
        return t('errors.auth.missingHashAlgorithm')
      case 'auth/missing-ios-bundle-id':
        return t('errors.auth.missingIosBundleId')
      case 'auth/missing-uid':
        return t('errors.auth.missingUid')
      case 'auth/missing-oauth-client-secret':
        return t('errors.auth.missingOauthClientSecret')
      case 'auth/operation-not-allowed':
        return t('errors.auth.operationNotAllowed')
      case 'auth/phone-number-already-exists':
        return t('errors.auth.phoneNumberAlreadyExists')
      case 'auth/project-not-found':
        return t('errors.auth.projectNotFound')
      case 'auth/reserved-claims':
        return t('errors.auth.reservedClaims')
      case 'auth/session-cookie-expired':
        return t('errors.auth.sessionCookieExpired')
      case 'auth/session-cookie-revoked':
        return t('errors.auth.sessionCookieRevoked')
      case 'auth/too-many-requests':
        return t('errors.auth.tooManyRequests')
      case 'auth/uid-already-exists':
        return t('errors.auth.uidAlreadyExists')
      case 'auth/unauthorized-continue-uri':
        return t('errors.auth.unauthorizedContinueUri')
      case 'auth/user-not-found':
        return t('errors.auth.userNotFound')
      case 'auth/cancelled-popup-request':
        return t('errors.auth.cancelledPopupRequest')
      default:
        return t('errors.auth.generic')
    }
  }

  return { getFirebaseErrorMessage }
}
