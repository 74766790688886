<template>
  <div class="main">
    <UpsellBanner v-if="!store.userHasSubscription" />
    <Text is="h3" serif>{{ greeting }}, {{ userName }}!</Text>
    <Spinner v-if="loading" />

    <!-- Display message when filteredSpaces is empty -->
    <div v-if="isEmptyCollection">
      <br />
      <br />
      <Text is="h1" :style="{ color: 'var(--color-warning-50)' }">
        {{ t('main.oops') }}
      </Text>
      <br />
      <Text is="h2">
        {{
          collectionName === 'favorites'
            ? t('main.yourCollection')
            : t('main.ourCollection')
        }}
        {{ t(`main.${collectionName}CollectionEmpty`) }}
      </Text>
      <br />
      <Text v-if="collectionName === 'favorites'">
        <router-link
          :to="{
            name: 'main',
            query: { ...router.currentRoute.value.query, filter: 'shuffle' }
          }"
          :style="{ color: 'var(--color-accent-50)' }"
        >
          {{ t('main.startExploring') }}
        </router-link>
        {{ t('main.addFavorites') }}
      </Text>
      <Text v-else>
        {{ t('main.tryReloading') }}
        <router-link
          :to="{
            name: 'main',
            query: { ...router.currentRoute.value.query, filter: 'shuffle' }
          }"
          :style="{ color: 'var(--color-accent-50)' }"
        >
          {{ t('main.exploreOtherCollections') }}
        </router-link>
      </Text>
    </div>

    <main v-else class="main__cards">
      <Card
        v-for="space in filteredSpaces"
        :key="space?.imageId"
        :soundId="space?.[lang].soundId"
        :imageId="space?.imageId"
        :title="space?.[lang].title"
        :details="
          router.currentRoute.value?.query?.filter === 'session' ||
          router.currentRoute.value?.query?.filter === 'meditation'
            ? space?.[lang].details
            : ''
        "
        :labels="space?.[lang].labels"
        :icons="space.icons"
        :isNew="space.new"
        :type="space.type"
        :favorite="space.favorite"
        :requiresPlan="space.requiresPlan"
        @toggleFavorite="handleToggleFavorite"
      />
    </main>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Spaces } from '@/data/spaces'
import { useUser } from '@/hooks/useUser'
import { useI18n } from 'vue-i18n'
import { lang } from '@/services/i18n'
import { shuffleArray } from '@/utils/functions'
import { useMainStore } from '@/state/index'
import UpsellBanner from '@/components/UpsellBanner.vue'

// Define component options
defineOptions({
  name: 'MainView'
})

const loading = ref(true) // General loading state for any collection
const store = useMainStore() // Accessing the store
const { userName, getUserFavorites } = useUser()
const route = useRoute()
const router = useRouter()
const filter = computed(() => route.query.filter || 'main')

// Computed property to get the collection name based on the current filter
const collectionName = computed(() => {
  switch (filter.value) {
    case 'nature':
      return 'nature'
    case 'urban':
      return 'urban'
    case 'music':
      return 'music'
    case 'session':
      return 'session'
    case 'favorites':
      return 'favorites'
    case 'shuffle':
      return 'shuffle'
    default:
      return 'sound'
  }
})

// Fetch user favorites and update state when the component is mounted
onMounted(async () => {
  try {
    loading.value = true // Show spinner while fetching data
    const userFavorites = await getUserFavorites()
    store.setFavoriteStatus(userFavorites) // Sync favorites to the store
    updateFilteredSpaces() // Filter the spaces immediately after loading
  } catch (error) {
    console.error('Error fetching favorites:', error)
  } finally {
    loading.value = false // Hide spinner when done
  }
})

// Re-filter the spaces when the favorites or filter change
watchEffect(() => {
  updateFilteredSpaces()
})

// Function to update the filtered spaces based on the current filter and favorites
function updateFilteredSpaces() {
  loading.value = true // Show spinner when filtering spaces
  let filtered
  const newValue = filter.value

  if (newValue === 'nature') {
    filtered = Object.fromEntries(
      shuffleArray(
        Object.entries(Spaces).filter(([, space]) => space.type === 'nature')
      )
    )
  } else if (newValue === 'urban') {
    filtered = Object.fromEntries(
      shuffleArray(
        Object.entries(Spaces).filter(([, space]) => space.type === 'urban')
      )
    )
  } else if (newValue === 'music') {
    filtered = Object.fromEntries(
      shuffleArray(
        Object.entries(Spaces).filter(([, space]) => space.type === 'music')
      )
    )
  } else if (newValue === 'session') {
    filtered = Object.fromEntries(
      shuffleArray(
        Object.entries(Spaces).filter(([, space]) => space.type === 'session')
      )
    )
  } else if (newValue === 'meditation') {
    filtered = Object.fromEntries(
      shuffleArray(
        Object.entries(Spaces).filter(
          ([, space]) => space.type === 'meditation'
        )
      )
    )
  } else if (newValue === 'favorites') {
    // Apply filtering and shuffle for favorites across all types, including sessions
    filtered = Object.fromEntries(
      Object.entries(Spaces).filter(([spaceId]) =>
        store.userFavorites.includes(spaceId)
      )
    )
  } else if (newValue === 'shuffle') {
    // Shuffle spaces only if not already shuffled
    if (!Object.keys(store.shuffledSpaces).length) {
      const entries = Object.entries(Spaces)
      const shuffledSpaces = shuffleArray(entries)
      store.setShuffledSpaces(Object.fromEntries(shuffledSpaces))
    }
    filtered = store.shuffledSpaces
  } else {
    filtered = Object.fromEntries(shuffleArray(Object.entries(Spaces)))
  }

  store.setFilteredSpaces(filtered) // Sync the filtered spaces to the store
  store.currentTrackIndex = -1 // Reset track index to the first item
  loading.value = false // Hide spinner when filtering is done
}

// Function to handle the toggle favorite action from the Card component
const handleToggleFavorite = async (soundId: string) => {
  loading.value = true // Show spinner when toggling a favorite
  await store.toggleFavorite(soundId) // Call the store action to toggle favorite
  loading.value = false // Hide spinner after the action is done
}

const filteredSpaces = computed(() => store.filteredSpaces) // Get filtered spaces from the store

// Computed to check if the collection is empty
const isEmptyCollection = computed(
  () => Object.keys(filteredSpaces.value).length === 0
)

const { t } = useI18n()

const greeting = computed(() => {
  const hour = new Date().getHours()
  if (hour < 12) {
    return t('user.greeting.morning')
  } else if (hour < 18) {
    return t('user.greeting.afternoon')
  } else {
    return t('user.greeting.evening')
  }
})
</script>

<style scoped lang="scss">
.main__cards {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--size-card-header-min-size), 1fr)
  );
  gap: var(--size-100);
  padding: var(--size-100) 0 0;
}
</style>
