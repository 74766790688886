<template>
  <div class="auth-socials">
    <!-- Social Sign-in Buttons -->
    <button @click="signInWithGoogle" class="auth-socials__button">
      <Text is="h5" centered>{{ t('authSocials.google') }}</Text>
    </button>
    <!-- <button @click="signInWithFacebook" class="auth-socials__button">
      <Text is="h5" centered>{{ t('authSocials.facebook') }}</Text>
    </button> -->
    <Text v-if="errorMessage" class="auth-socials__error">
      {{ errorMessage }}
    </Text>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { auth } from '@/services/firebase' // Ensure this path matches your actual firebase config file
import {
  signInWithPopup,
  GoogleAuthProvider,
  // FacebookAuthProvider,
  UserCredential,
  AuthError
} from 'firebase/auth'
import { useI18n } from 'vue-i18n'
import { useError } from '@/hooks/useError'

const { t } = useI18n()
const { getFirebaseErrorMessage } = useError()
const errorMessage = ref<string>('')
const router = useRouter()

// Google Sign-In
const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  try {
    const result: UserCredential = await signInWithPopup(auth, provider)
    router.push({ name: 'main' }) // Redirect to main route after successful login
    return result.user
  } catch (error: unknown) {
    const authError = error as AuthError
    errorMessage.value = getFirebaseErrorMessage(authError.code)
    console.error('Google sign-in error: ', authError.message)
  }
}

// Facebook Sign-In
// const signInWithFacebook = async () => {
//   const provider = new FacebookAuthProvider()
//   try {
//     const result: UserCredential = await signInWithPopup(auth, provider)
//     router.push({ name: 'main' }) // Redirect to main route after successful login
//     return result.user
//   } catch (error: unknown) {
//     const authError = error as AuthError
//     errorMessage.value = getFirebaseErrorMessage(authError.code)
//     console.error('Facebook sign-in error: ', authError.message)
//   }
// }
</script>

<style lang="scss" scoped>
.auth-socials {
  display: grid;
  gap: var(--size-30);
}

.auth-socials__button {
  background-color: var(--color-accent-50-32);
  color: var(--color-body-text);
  padding: var(--size-40) var(--size-80);
  border: 0;
  outline: 0;
  margin-top: var(--size-40);
  border-radius: var(--size-160);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-accent-50);
    color: var(--color-neutral-90);
  }
}

.auth-socials__error {
  margin-top: var(--size-10);
  color: var(--color-warning-50);
}
</style>
