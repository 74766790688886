/**
 * Formats a price amount to the specified currency using Intl.NumberFormat.
 *
 * @param {number} amount - The amount in cents (for example, 100 cents for €1.00).
 * @param {string} currency - The currency code (e.g., 'EUR', 'USD').
 * @returns {string} - The formatted price string based on the currency and locale.
 */
export function formatPrice(amount: number, currency: string): string {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2
  }).format(amount / 100) // Convert cents to euros
}

/**
 * Shuffles an array using the Fisher-Yates (aka Knuth) Shuffle algorithm.
 *
 * @param {T[]} array - The array to shuffle.
 * @returns {T[]} - The shuffled array.
 */
export function shuffleArray<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // Swap it with the current element.
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ]
  }

  return array
}

/**
 * Helper function to format a given time in seconds into a readable string
 * representing days, hours, minutes, and seconds.
 *
 * @param {number} seconds - The total time in seconds to format.
 * @param {Function} t - The translation function from i18n (optional, defaults to English).
 * @returns {string} A string representing the time in days, hours, minutes, and seconds.
 */
export function formatTime(
  seconds: number,
  t: (key: string, count: number) => string
): string {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  let result = ''

  if (days > 0) {
    result += `${days} ${t('time.days', days)}`
  }
  if (hours > 0) {
    result += `${result ? ', ' : ''}${hours} ${t('time.hours', hours)}`
  }
  if (minutes > 0) {
    result += `${result ? ', ' : ''}${minutes} ${t('time.minutes', minutes)}`
  }

  // Always include seconds
  result += `${result ? ', ' : ''}${secs} ${t('time.seconds', secs)}`

  return result
}

/**
 * Helper function to get the ISO week number for a given date.
 *
 * @param {Date} date - The date for which to calculate the ISO week number.
 * @returns {number} - The ISO week number for the given date.
 */
const getISOWeekNumber = (date: Date): number => {
  const tempDate = new Date(date.getTime())
  tempDate.setDate(tempDate.getDate() + 4 - (tempDate.getDay() || 7)) // Adjust to the nearest Thursday.
  const yearStart = new Date(tempDate.getFullYear(), 0, 1)
  const weekNo = Math.ceil(
    ((tempDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  )
  return weekNo
}

/**
 * Generates a daily key in the format YYYY-MM-DD based on the user's local time.
 *
 * @returns {string} - The daily key as a string in the format YYYY-MM-DD.
 */
export const getDailyKey = (): string => {
  const today = new Date()
  return today.toLocaleDateString('en-CA') // Formats date as YYYY-MM-DD
}

/**
 * Generates a weekly key in the format YYYY-WXX (ISO Week) based on the user's local time.
 *
 * @returns {string} - The weekly key as a string in the format YYYY-WXX.
 */
export const getWeeklyKey = (): string => {
  const today = new Date()
  const year = today.getFullYear()
  const week = getISOWeekNumber(today)
  return `${year}-W${week}`
}

/**
 * Generates a monthly key in the format YYYY-MM based on the user's local time.
 *
 * @returns {string} - The monthly key as a string in the format YYYY-MM.
 */
export const getMonthlyKey = (): string => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // Month starts from 0, so add 1
  return `${year}-${month}`
}

/**
 * Generates a yearly key in the format YYYY based on the user's local time.
 *
 * @returns {string} - The yearly key as a string in the format YYYY.
 */
export const getYearlyKey = (): string => {
  const today = new Date()
  return today.getFullYear().toString()
}
