import { TypeSpaces } from './spaces'

export const Meditations: TypeSpaces = {
  'reframing-stress': {
    en: {
      labels: [
        '4:48 min.',
        'Reframing stress',
        'Mindful breathing',
        'Letting go'
      ],
      title: 'Guided meditation for reframing stress',
      details:
        'This guided meditation helps you release stress by focusing on your breath and body, encouraging a calm and mindful awareness. Let go of distractions and restore inner peace with this simple practice',
      soundId: 'reframing-stress-en'
    },
    es: {
      labels: [
        '4:48 min.',
        'Reformular el estrés',
        'Respiración consciente',
        'Dejar ir'
      ],
      title: 'Meditación guiada para la reformulación del estrés',
      details:
        'Esta meditación guiada te ayuda a liberar el estrés al enfocarte en tu respiración y cuerpo, fomentando una calma y atención plena. Suelta las distracciones y restaura la paz interior con esta práctica sencilla',
      soundId: 'reframing-stress-es'
    },
    type: 'meditation',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'reframing-stress'
  }
}
