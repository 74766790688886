import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions } from 'firebase/functions'

const region =
  process.env.VUE_APP_ENV === 'production'
    ? process.env.VUE_APP_FB_PROD_REGION
    : process.env.VUE_APP_FB_DEV_REGION

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_DEV_API_KEY,
  authDomain: process.env.VUE_APP_FB_DEV_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DEV_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_DEV_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_DEV_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_DEV_APP_ID,
  measurementId: process.env.VUE_APP_FB_DEV_MEASUREMENT_ID
}

if (process.env.VUE_APP_ENV === 'production') {
  firebaseConfig.apiKey = process.env.VUE_APP_FB_PROD_API_KEY
  firebaseConfig.authDomain = process.env.VUE_APP_FB_PROD_AUTH_DOMAIN
  firebaseConfig.databaseURL = process.env.VUE_APP_FB_PROD_DATABASE_URL
  firebaseConfig.projectId = process.env.VUE_APP_FB_PROD_PROJECT_ID
  firebaseConfig.storageBucket = process.env.VUE_APP_FB_PROD_STORAGE_BUCKET
  firebaseConfig.messagingSenderId =
    process.env.VUE_APP_FB_PROD_MESSAGING_SENDER_ID
  firebaseConfig.appId = process.env.VUE_APP_FB_PROD_APP_ID
  firebaseConfig.measurementId = process.env.VUE_APP_FB_PROD_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig)

export const analytics = getAnalytics(firebaseApp)
export const auth = getAuth(firebaseApp)
export const db = getFirestore(firebaseApp)
export const functions = getFunctions(firebaseApp, region)
