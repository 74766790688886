<template>
  <div v-if="isVisible" class="modal">
    <div class="modal__container" @click.self="close">
      <div class="modal__content">
        <button class="modal__close-button" @click="close">
          <Icon icon="x" />
        </button>
        <header v-if="title || subtitle" class="modal__header">
          <Text v-if="title" is="h2" black>{{ title }}</Text>
          <Text v-if="subtitle" is="h4">{{ subtitle }}</Text>
        </header>
        <div v-if="body || hasBodySlot || component" class="modal__body">
          <Text v-if="body">{{ body }}</Text>
          <slot v-if="hasBodySlot" name="body" />
          <component v-if="component" :is="component" />
        </div>
        <footer v-if="buttonText || hasFooterSlot" class="modal__footer">
          <button
            v-if="buttonText && buttonAction"
            type="button"
            @click="handleButtonClick"
            class="modal__footer-button"
          >
            <Text is="h5">{{ buttonText }}</Text>
          </button>
          <slot name="footer" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  watch,
  defineEmits,
  defineComponent,
  computed,
  useSlots
} from 'vue'
import Icon from '@/components/Icon.vue'
import Text from '@/components/Text.vue'

defineOptions({
  name: 'ModalComponent'
})

const props = defineProps<{
  visible: boolean
  title?: string
  subtitle?: string
  body?: string
  component?: ReturnType<typeof defineComponent>
  buttonAction?: () => void
  buttonText?: string
}>()

const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void
}>()

const slots = useSlots()
const hasBodySlot = computed(() => !!slots.body)
const hasFooterSlot = computed(() => !!slots.footer)

// Make isVisible reactive and bind it to props.visible
const isVisible = ref(props.visible)
watch(
  () => props.visible,
  (newVal) => {
    isVisible.value = newVal
  }
)

const close = () => {
  emit('update:visible', false)
}

const handleButtonClick = () => {
  if (props.buttonAction) {
    props.buttonAction()
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-color: var(--color-modal-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-high);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
  }
}

.modal__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 0;
  width: inherit;
  height: inherit;

  @media (min-width: $device-md) {
    padding: var(--size-40);
  }

  @media (min-width: $device-lg) {
    padding: var(--size-80);
  }

  @media (min-width: $device-xl) {
    padding: var(--size-120);
  }
}

.modal__content {
  width: inherit;
  height: inherit;
  margin: 0 auto;
  padding: var(--size-40);
  box-shadow: 0 var(--size-5) 10px var(--color-neutral-80-2-inverted);
  background-color: var(--color-body-bg);
  overflow-y: scroll;

  :-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: $device-md) {
    display: initial;
    max-width: var(--size-modal-max-width);
    border-radius: var(--size-40);
    padding: var(--size-80);
    box-shadow: 0 var(--size-5) 10px var(--color-neutral-80-2-inverted);
  }
}

.modal__header {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  padding-right: var(--size-100);

  @media (min-width: $device-md) {
    padding-right: 0;
  }
}

.modal__close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: var(--size-30);
  right: var(--size-30);
  background-color: var(--color-neutral-90);
  border: none;
  border-radius: var(--size-radius-round);
  cursor: pointer;
  color: inherit;
  width: var(--size-100);
  height: var(--size-100);
  padding: 0;
  z-index: var(--z-index-top);

  &:hover {
    transform: scale(1.1);
    transform-origin: center;
    transition: 0.3s ease-in-out;

    .icon {
      transform: scale(0.8);
      transform-origin: center;
      transition: 0.3s ease-in-out;
    }
  }
}

.modal__body {
  padding: var(--size-40) 0;
  gap: var(--size-40);
}

.modal__footer {
  display: flex;
  padding: var(--size-40) 0;
}

.modal__footer-button {
  padding: var(--size-40) var(--size-80);
  border: 0;
  outline: 0;
  border-radius: var(--size-160);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: var(--color-body-text);
  color: var(--color-neutral-90);

  &:hover {
    background-color: var(--color-accent-50);
  }
}
</style>
