import { createI18n } from 'vue-i18n'
import { en } from '@/lang/en'
import { es } from '@/lang/es'

const supportedLanguages = ['en', 'es']

function detectLanguage(): string {
  // Step 1: Check if the URL has a 'lang' parameter
  const urlParams = new URLSearchParams(window.location.search)
  const urlLang = urlParams.get('lang')
  if (urlLang && supportedLanguages.includes(urlLang)) {
    localStorage.setItem('lang', urlLang) // Persist to localStorage
    return urlLang
  }

  // Step 2: Check if a language is stored in localStorage
  const storedLang = localStorage.getItem('lang')
  if (storedLang && supportedLanguages.includes(storedLang)) {
    return storedLang
  }

  // Step 3: Default to browser language if no URL or stored language
  const browserLang = navigator.language.split('-')[0]
  const detectedLang = supportedLanguages.includes(browserLang)
    ? browserLang
    : 'en'
  localStorage.setItem('lang', detectedLang) // Persist to localStorage
  return detectedLang
}

// Create i18n instance with the detected language
export const i18n = createI18n({
  locale: detectLanguage(),
  fallbackLocale: 'en',
  messages: {
    en,
    es
  }
})

// Export the current language for global usage
export const lang = i18n.global.locale
