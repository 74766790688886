<template>
  <div>
    <button
      type="button"
      class="collapsible"
      :class="{ active: isOpen }"
      @click="toggle"
    >
      <Text inline>{{ title }}</Text>
    </button>
    <div v-show="isOpen" class="content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CollapsibleComponent'
}
</script>

<script setup lang="ts">
import { ref } from 'vue'

defineProps({
  title: String
})

const isOpen = ref(false)

function toggle() {
  isOpen.value = !isOpen.value
}
</script>

<style lang="scss" scoped>
.collapsible {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  padding: var(--size-40);
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}

.active,
.collapsible:hover {
  background-color: inherit;
}

.content {
  padding: 0;
  overflow: hidden;
}
</style>
