<template>
  <label class="switch">
    <input
      type="checkbox"
      class="switch__input"
      :checked="modelValue"
      @change="handleChange"
    />
    <span class="switch__slider"></span>
  </label>
</template>

<script lang="ts">
export default {
  name: 'SwitchComponent'
}
</script>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    modelValue?: boolean
    isLightAndDark?: boolean
  }>(),
  {
    modelValue: false,
    isLightAndDark: false
  }
)

const emit = defineEmits(['update:modelValue'])

const handleChange = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).checked)
}
</script>

<style scoped lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: var(--size-120);
  height: var(--size-60);

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-neutral-30);
    border-radius: var(--size-60);

    &:before {
      position: absolute;
      content: '';
      height: var(--size-50);
      width: var(--size-50);
      left: var(--size-1);
      bottom: var(--size-1);
      background-color: var(--color-body-bg);
      border-radius: 50%;
      border: var(--size-1) solid var(--color-accent-50);
      transition: transform 0.3s ease-in-out;
      transform: translateX(0); // Explicit initial state for transition
    }
  }

  &__input:checked + &__slider {
    &:before {
      transform: translateX(
        var(--size-60)
      ); // Ensure this matches the slider's movement requirement
    }
  }
}
</style>
