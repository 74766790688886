import { ref } from 'vue'
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore'
import { db } from '@/services/firebase'
import {
  getDailyKey,
  getWeeklyKey,
  getMonthlyKey,
  getYearlyKey
} from '@/utils/functions'

// Define the common structure for storing stats
export interface TypeStats {
  daily: Record<string, number>
  weekly: Record<string, number>
  monthly: Record<string, number>
  yearly: Record<string, number>
  total: number
}

// Use this as a composition function
export function useStats() {
  const breathingStats = ref<TypeStats>({
    daily: {},
    weekly: {},
    monthly: {},
    yearly: {},
    total: 0
  })

  const doNothingStats = ref<TypeStats>({
    daily: {},
    weekly: {},
    monthly: {},
    yearly: {},
    total: 0
  })

  // Fetch breathing stats from Firestore
  const fetchBreathingStats = async (userId: string) => {
    if (!userId) {
      console.error('User ID is required to fetch breathing stats')
      return
    }

    const statsRef = doc(db, 'users', userId)

    try {
      const statsSnap = await getDoc(statsRef)

      if (statsSnap.exists()) {
        const statsData = statsSnap.data().breathingTime || {}
        breathingStats.value = {
          daily: statsData.daily || {},
          weekly: statsData.weekly || {},
          monthly: statsData.monthly || {},
          yearly: statsData.yearly || {},
          total: statsData.total || 0
        }
        // console.log('Breathing stats fetched successfully from Firebase')
      } else {
        // console.warn('No breathing stats found for this user')
      }
    } catch (error) {
      // console.error('Error fetching breathing stats from Firebase:', error)
    }
  }

  // Track user's breathing time by incrementing the relevant fields in Firestore
  const updateBreathingTime = async (userId: string, timeInSeconds: number) => {
    if (!userId) {
      console.error('User ID is required to track breathing time')
      return
    }

    const statsRef = doc(db, 'users', userId) // Reference to user's document

    const dailyKey = getDailyKey()
    const weeklyKey = getWeeklyKey()
    const monthlyKey = getMonthlyKey()
    const yearlyKey = getYearlyKey()

    try {
      // Update Firestore by incrementing the breathing stats
      await updateDoc(statsRef, {
        [`breathingTime.daily.${dailyKey}`]: increment(timeInSeconds),
        [`breathingTime.weekly.${weeklyKey}`]: increment(timeInSeconds),
        [`breathingTime.monthly.${monthlyKey}`]: increment(timeInSeconds),
        [`breathingTime.yearly.${yearlyKey}`]: increment(timeInSeconds),
        [`breathingTime.total`]: increment(timeInSeconds)
      })

      // console.log('Breathing time updated successfully in Firebase')
    } catch (error) {
      // console.error('Error updating breathing time in Firebase:', error)
    }
  }

  // Fetch do nothing stats from Firestore
  const fetchDoNothingStats = async (userId: string) => {
    if (!userId) {
      console.error('User ID is required to fetch do nothing stats')
      return
    }

    const statsRef = doc(db, 'users', userId)

    try {
      const statsSnap = await getDoc(statsRef)

      if (statsSnap.exists()) {
        const statsData = statsSnap.data().doNothingTime || {}
        doNothingStats.value = {
          daily: statsData.daily || {},
          weekly: statsData.weekly || {},
          monthly: statsData.monthly || {},
          yearly: statsData.yearly || {},
          total: statsData.total || 0
        }
        // console.log('Do nothing stats fetched successfully from Firebase')
      } else {
        // console.warn('No do nothing stats found for this user')
      }
    } catch (error) {
      // console.error('Error fetching do nothing stats from Firebase:', error)
    }
  }

  // Track user's do nothing time by incrementing the relevant fields in Firestore
  const updateDoNothingTime = async (userId: string, timeInSeconds: number) => {
    if (!userId) {
      console.error('User ID is required to track do nothing time')
      return
    }

    const statsRef = doc(db, 'users', userId) // Reference to user's document

    const dailyKey = getDailyKey()
    const weeklyKey = getWeeklyKey()
    const monthlyKey = getMonthlyKey()
    const yearlyKey = getYearlyKey()

    try {
      // Update Firestore by incrementing the do nothing stats
      await updateDoc(statsRef, {
        [`doNothingTime.daily.${dailyKey}`]: increment(timeInSeconds),
        [`doNothingTime.weekly.${weeklyKey}`]: increment(timeInSeconds),
        [`doNothingTime.monthly.${monthlyKey}`]: increment(timeInSeconds),
        [`doNothingTime.yearly.${yearlyKey}`]: increment(timeInSeconds),
        [`doNothingTime.total`]: increment(timeInSeconds)
      })

      // console.log('Do nothing time updated successfully in Firebase')
    } catch (error) {
      // console.error('Error updating do nothing time in Firebase:', error)
    }
  }

  return {
    breathingStats,
    updateBreathingTime,
    fetchBreathingStats,
    doNothingStats,
    updateDoNothingTime,
    fetchDoNothingStats
  }
}
