const companyName = 'Spaces.fm'
const companySloganEs = 'relaja la mente de mono'

export const es = {
  company: {
    name: companyName,
    slogan: companySloganEs
  },
  copyright: `Derechos de autor © 2024 ${companyName}. Todos los derechos reservados.`,
  loader: {
    title: `Cargando ${companyName}...`,
    message: `Siéntate y ${companySloganEs}.`
  },
  modal: {
    premium: {
      subtitle: 'Este sonido es parte de nuestro plan premium.',
      body: 'Descubre sonidos transformadores, sesiones guiadas y meditaciones diseñadas para mejorar tu enfoque, aliviar el estrés y ayudarte a relajarte cuando lo necesites, con Spaces.fm premium.'
    },
    upsellBanner: {
      title: 'Experimenta todo el poder de Spaces.fm',
      subtitle:
        'Desbloquea todos nuestros sonidos, meditaciones y sesiones para concentración y relajación.',
      body: 'Obtén acceso a sonidos exclusivos, meditaciones y sesiones guiadas, diseñados para mejorar la concentración, la relajación y el crecimiento personal. Únete a nuestra comunidad y desbloquea tu potencial.'
    }
  },
  toast: {
    player: {
      loopEnabled: {
        title: 'Repetición Activada',
        message: 'La repetición está activada para reproducción continua.'
      },
      loopDisabled: {
        title: 'Repetición Desactivada',
        message:
          'La repetición está desactivada. Las pistas se reproducirán secuencialmente.'
      },
      soundNotFound: {
        title: 'Sonido no encontrado',
        message:
          '¡Lo sentimos! El título que buscas no está disponible en este momento. Intenta refrescar tu navegador o elige otro título.'
      }
    },
    favorites: {
      removedFromFavorites: 'Eliminado de Favoritos',
      addedToFavorites: 'Agregado a Favoritos',
      removedFromFavoritesMessage: 'ha sido eliminado de tus favoritos.',
      addedToFavoritesMessage: 'ha sido agregado a tus favoritos.'
    },
    account: {
      reauthErrorTitle: 'Error de reautenticación',
      nameUpdatedTitle: '¡Nombre actualizado!',
      nameUpdatedMessage: 'Tu nombre ha sido actualizado a {name}.',
      nameUpdateErrorTitle: 'Error al actualizar tu nombre',
      verificationSentTitle: 'Correo de verificación enviado',
      verificationSentMessage:
        'Se ha enviado un correo de verificación a {email}. Por favor revisa tu bandeja de entrada.',
      verificationErrorTitle: 'Error al enviar la verificación',
      passwordUpdatedTitle: 'Contraseña actualizada',
      passwordUpdatedMessage: 'Tu contraseña ha sido actualizada con éxito.',
      passwordUpdateErrorTitle: 'Error al actualizar la contraseña'
    }
  },
  upsellBanner: {
    message: `Te estás perdiendo la experiencia completa de ${companyName}. Suscríbete hoy para desbloquear contenido premium diseñado para elevar tu bienestar.`
  },
  main: {
    oops: '¡Oh no!',
    yourCollection: 'Tu',
    ourCollection: 'Nuestra',
    favoritesCollectionEmpty: 'colección de favoritos está vacía.',
    natureCollectionEmpty: 'colección de naturaleza está vacía.',
    urbanCollectionEmpty: 'colección urbana está vacía.',
    musicCollectionEmpty: 'colección de música está vacía.',
    sessionCollectionEmpty: 'colección de sesiones está vacía.',
    shuffleCollectionEmpty: 'colección aleatoria está vacía.',
    soundCollectionEmpty: 'colección de sonidos está vacía.',
    startExploring: 'Comienza a explorar',
    addFavorites: ' y agrega algunos sonidos a tus favoritos.',
    tryReloading: 'Por favor, intenta recargar la página o',
    exploreOtherCollections: 'explora otras colecciones.'
  },
  account: {
    header: 'Tu cuenta',
    details: 'Tus detalles.',
    changeName: 'Cambia tu nombre:',
    newNamePlaceholder: 'Introduce tu nuevo nombre',
    updateNameButton: 'Actualizar mi nombre',
    currentEmail: 'Tu correo electrónico actual:',
    newEmail: 'Introduce tu nuevo correo electrónico:',
    newEmailPlaceholder: 'Introduce nuevo correo electrónico',
    sendVerificationButton: 'Enviar verificación al nuevo correo',
    verificationSentMessage:
      'Correo de verificación enviado a {email}. Por favor, revisa tu bandeja de entrada.',
    changePassword: 'Cambia tu contraseña:',
    newPasswordPlaceholder: 'Introduce tu nueva contraseña',
    updatePasswordButton: 'Actualizar contraseña',
    subscription: 'Tu suscripción.',
    userUid: 'Tu ID de usuario.',
    appVersion: 'Versión de la aplicación',
    reauthTitle: '¡Primero reautentícate!',
    reauthSubtitle: 'Por favor, reautentícate para continuar.',
    reauthButton: 'Reautenticar'
  },
  doNothing: {
    doNothingFor: 'No hagas nada durante',
    minutes: 'minutos.',
    wellDone: '¡Bien hecho!',
    tryAgain: '¡Inténta de nuevo!',
    restart: 'Reiniciar',
    start: 'Empezar',
    instructionsInProgress: 'No toques el ratón, el trackpad o el teclado.',
    instructionsStart:
      "Presiona cualquier tecla o el botón 'Empezar' para comenzar.",
    totalTime: "Tu tiempo total actual de 'No hacer nada': {time}."
  },
  justBreathe: {
    toast: {
      title: 'Personaliza tus intervalos de respiración.',
      message:
        'Ajusta tus tiempos de inhalación, exhalación y retención para una experiencia de respiración personalizada.'
    },
    controls: {
      restart: 'Reiniciar',
      inhale: 'Inhalar',
      holdIn: 'Mantener inhalación',
      exhale: 'Exhalar',
      holdOut: 'Mantener exhalación'
    },
    instructions: {
      inhale: 'Inhala',
      holdIn: 'Mantén',
      exhale: 'Exhala',
      holdOut: 'Mantén'
    },
    start: 'Iniciar',
    stop: 'Detener',
    followInstruction:
      'Concéntrate en tu respiración. Inhala, mantén y exhala siguiendo los círculos.',
    startInstruction:
      "Presiona cualquier tecla o el botón 'Iniciar' para comenzar.",
    totalTime: 'Tu tiempo total actual de respiración: {time}.'
  },
  wiseWords: {
    slideDelayLabel: 'Retraso de diapositiva en segundos',
    secondsLeft: {
      smallDevice: 'Segundos.',
      largeDevice: 'Segundos restantes.'
    },
    footerButtons: {
      prev: 'Anterior',
      next: 'Siguiente',
      play: 'Reanudar',
      pause: 'Pausar'
    },
    quote: 'Cita',
    author: 'Autor'
  },
  prices: {
    loader: {
      message: 'Relájate mientras te conectamos con nuestro proveedor de pagos.'
    },
    perMonth: '/mes',
    bestValue: 'Mejor valor',
    interval: {
      yearly: 'Anual',
      monthly: 'Mensual'
    },
    daysFree: '7 días gratis',
    billedAnnually: 'Facturado anualmente a {amount}.',
    enterVoucher: 'Introduce tu código de descuento',
    tryForFree: `Prueba {companyName} gratis`,
    membershipSelection: 'Selecciona una membresía para continuar.',
    freeTrialMessage:
      'Después de tu prueba gratuita de {days} días, son {price} por {interval}, renovación automática. Cancela en cualquier momento.',
    activeSubscription: '¡Tienes una suscripción activa!',
    updateSubscription: 'Actualizar mi suscripción',
    skipSubscription: 'Continuar sin suscripción'
  },
  user: {
    greeting: {
      morning: 'Buenos días',
      afternoon: 'Buenas tardes',
      evening: 'Buenas noches'
    }
  },
  sidebar: {
    sections: [
      {
        title: 'Colecciones',
        tabs: {
          shuffle: 'Aleatorio',
          nature: 'Naturaleza',
          urban: 'Urbano',
          music: 'Música',
          sessions: 'Sesiones',
          meditations: 'Meditaciones',
          favorites: 'Favoritos'
        }
      },
      {
        title: 'Applicaciones',
        tabs: {
          doNothing: 'No hagas nada',
          justBreathe: 'Solo respira',
          wiseWords: 'Palabras sabias'
        }
      },
      {
        title: 'Configuración',
        tabs: {
          account: 'Mi cuenta',
          stats: 'Mis estadísticas',
          awards: 'Mis premios',
          mode: {
            light: 'Modo oscuro',
            dark: 'Modo claro'
          }
        }
      },
      {
        tabs: {
          collapse: 'Colapsar sidebar'
        }
      }
    ]
  },
  player: {
    loading: 'Cargando',
    favorites: {
      add: 'Agregar a mis favoritos',
      remove: 'Eliminar de mis favoritos'
    }
  },
  card: {
    new: 'Nuevo'
  },
  time: {
    seconds: 'segundo | segundos',
    minutes: 'minuto | minutos',
    hours: 'hora | horas',
    days: 'día | días'
  },
  auth: {
    header: {
      newTo: `¿Nuevo en ${companyName}?`,
      tryForFree: `Prueba ${companyName} gratis`,
      alreadyHaveAccount: '¿Ya tienes una cuenta?',
      logIn: 'Inicia sesión en tu cuenta.'
    },
    signup: {
      alreadyAccount: '¿Ya tienes una cuenta?'
    },
    login: {
      welcomeBack: '¡Bienvenido de nuevo!',
      readyToDive:
        'Nos alegra verte de nuevo. ¿Listo para sumergirte en tus sonidos favoritos?'
    },
    social: {
      or: 'O'
    }
  },
  authLogin: {
    heading: 'Inicia sesión en tu cuenta.',
    emailPlaceholder: 'Tu correo electrónico *',
    passwordPlaceholder: 'Tu contraseña *',
    forgotPassword: '¿Olvidaste tu contraseña?',
    continue: 'Continuar'
  },
  authSignUp: {
    heading: 'Crea una cuenta nueva.',
    namePlaceholder: 'Tu nombre *',
    emailPlaceholder: 'Tu correo electrónico *',
    passwordPlaceholder: 'Tu contraseña (8+ caracteres) *',
    continue: 'Continuar',
    errors: {
      passwordTooShort: 'La contraseña debe tener al menos 8 caracteres.'
    }
  },
  authEmailVerify: {
    almostThere: '¡Casi hemos terminado!',
    message: `Por favor verifica tu correo para garantizar una comunidad real y segura en ${companyName}. Solo toma un minuto.`,
    verifyEmail: 'Por favor, verifica tu correo para continuar.',
    checkInbox: 'Revisa tu bandeja de entrada para el enlace de verificación.',
    checkStatus: 'Verificar estado',
    didntReceiveEmail: '¿No recibiste el correo?',
    resendEmail: 'Reenviar verificación',
    emailVerified: '¡Correo verificado!',
    emailNotVerified: 'Tu correo no ha sido verificado aún.',
    resendEmailSuccess:
      '¡El correo de verificación ha sido enviado! Revisa tu bandeja de entrada.'
  },
  authLogOut: {
    buttonText: 'Cerrar sesión',
    successMessage: 'Sesión cerrada con éxito.',
    errorMessage: 'Error al cerrar sesión.'
  },
  authForgotPassword: {
    heading: '¿Olvidaste tu contraseña?',
    emailPlaceholder: 'Ingresa tu correo electrónico *',
    buttonText: 'Enviar enlace',
    resetEmailSent:
      '¡Correo enviado! Revisa tu bandeja de entrada y haz clic en el enlace para restablecer tu contraseña.',
    enterEmail:
      'Ingresa tu correo electrónico, y te enviaremos un enlace para restablecer tu contraseña.',
    redirectMessage:
      '¡Espera un momento! Te estamos redirigiendo a la pantalla de inicio de sesión.'
  },
  authResetPassword: {
    heading: 'Restablecer tu contraseña',
    newPasswordPlaceholder: 'Ingresa tu nueva contraseña *',
    confirmPasswordPlaceholder: 'Confirma tu nueva contraseña *',
    buttonText: 'Restablecer contraseña',
    invalidCode:
      'El enlace es inválido o ha expirado. Intenta nuevamente siguiendo el proceso de "olvidé mi contraseña".',
    yourEmail: 'Tu correo electrónico:',
    passwordMismatch: 'Las contraseñas deben coincidir.',
    passwordTooShort: 'Tu contraseña debe tener al menos 8 caracteres.',
    resetSuccess: '¡Éxito! Tu contraseña ha sido restablecida.',
    redirectMessage:
      '¡Espera! Redirigiéndote a la pantalla de inicio de sesión.'
  },
  authPrices: {
    fullExperienceFor: `Obtén la experiencia completa de ${companyName} por:`,
    today: 'hoy.',
    priceDetails:
      '{yearlyPricePerMonth} por mes, facturado anualmente a {yearlyPrice}/año después de tu prueba gratuita de 7 días, o {monthlyPrice} por mes, facturado mensualmente a {monthlyPrice}/mes después de tu prueba gratuita de 7 días.',
    accessCollection:
      'Accede a nuestra creciente colección de paisajes sonoros, música y sesiones guiadas.',
    noAds: 'Sin anuncios para una concentración y relajación ininterrumpidas.',
    accessTools:
      'Accede a nuestras herramientas como No hagas nada y Palabras sabias, con más funciones próximamente.',
    newContentDaily: 'Contenido nuevo a diario.',
    cancelAnytime: 'Cancela en cualquier momento.',
    selectPlan: 'Selecciona tu plan.'
  },
  authSocials: {
    google: 'Continuar con Google',
    facebook: 'Continuar con Facebook',
    error: 'Ocurrió un error durante el inicio de sesión social.'
  },
  stats: {
    title: 'Tus estadísticas',
    message:
      'Monitorea tu progreso con estos gráficos. Muestran el total de minutos diarios, semanales, mensuales y anuales. El azul representa "Solo respira" y el naranja "No hagas nada." ¡Sé constante y ve cómo crece tu tiempo de relajación!',
    noData: 'Aún no hay datos disponibles.',
    feature: {
      justBreathe: 'Solo respira',
      doNothing: 'No hagas nada',
      wiseWords: 'Palabras sabias'
    },
    total: 'Total',
    today: 'Hoy',
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
    yearly: 'Anual'
  },
  awards: {
    title: 'Tus premios',
    feature: {
      doNothing: 'No hagas nada',
      justBreathe: 'Solo respira',
      wiseWords: 'Palabras sabias'
    },
    levels: {
      initiate: 'Iniciado',
      novice: 'Novato',
      beginner: 'Principiante',
      adept: 'Adepto',
      skilled: 'Hábil',
      expert: 'Experto',
      guru: 'Gurú'
    }
  },
  errors: {
    noPricesTitle: 'No hay precios disponibles',
    noPricesMessage:
      'No se pueden cargar los precios. Por favor, inténtalo más tarde.',
    loginRequiredTitle: 'Inicio de sesión requerido',
    loginRequiredMessage: 'Por favor, inicia sesión para continuar.',
    membershipSelectionTitle: 'Selección de membresía',
    voucherErrorTitle: 'Error en el cupón',
    unexpectedErrorTitle: 'Error inesperado',
    unexpectedErrorMessage:
      'Ocurrió un error inesperado. Por favor, inténtalo más tarde.',
    portalErrorTitle: 'Error en el portal del cliente',
    portalErrorMessage:
      'No se puede abrir el portal del cliente. Por favor, inténtalo más tarde.',
    auth: {
      claimsTooLarge: 'Los datos de reclamación son demasiado grandes.',
      emailAlreadyExists: 'Este correo ya está en uso.',
      idTokenExpired: 'Tu sesión ha expirado. Inicia sesión nuevamente.',
      idTokenRevoked: 'Tu sesión ha sido revocada. Inicia sesión nuevamente.',
      insufficientPermission: 'No tienes los permisos necesarios.',
      internalError: 'Ocurrió un error interno. Inténtalo más tarde.',
      invalidArgument: 'Se proporcionó un argumento inválido.',
      invalidClaims: 'Se proporcionaron atributos de reclamo inválidos.',
      invalidContinueUri: 'La URL de continuación proporcionada no es válida.',
      invalidCreationTime: 'La hora de creación debe ser una fecha UTC válida.',
      invalidCredential:
        'Correo electrónico o contraseña incorrectos. Por favor, inténtalo de nuevo.',
      invalidDisabledField: 'El valor del campo deshabilitado no es válido.',
      invalidDisplayName: 'El nombre de usuario no puede estar vacío.',
      invalidDynamicLinkDomain:
        'El dominio del enlace dinámico no está autorizado.',
      invalidEmail: 'El correo electrónico proporcionado no es válido.',
      invalidEmailVerified: 'El estado de verificación de correo no es válido.',
      invalidHashAlgorithm: 'El algoritmo de hash no es compatible.',
      invalidHashBlockSize: 'El tamaño del bloque de hash no es válido.',
      invalidHashDerivedKeyLength:
        'La longitud de la clave derivada es inválida.',
      invalidHashKey: 'La clave de hash no es válida.',
      invalidHashMemoryCost: 'El costo de memoria del hash no es válido.',
      invalidHashParallelization: 'La paralelización del hash no es válida.',
      invalidHashRounds: 'El número de rondas de hash no es válido.',
      invalidHashSaltSeparator: 'El separador de sal del hash no es válido.',
      invalidIdToken: 'El ID token proporcionado no es válido.',
      invalidLastSignInTime:
        'El último tiempo de inicio de sesión no es válido.',
      invalidPageToken: 'El token de página siguiente no es válido.',
      invalidPassword: 'La contraseña proporcionada no es válida.',
      invalidPasswordHash: 'El hash de la contraseña no es válido.',
      invalidPasswordSalt: 'La sal de la contraseña no es válida.',
      invalidPhoneNumber: 'El número de teléfono no es válido.',
      invalidPhotoUrl: 'La URL de la foto no es válida.',
      invalidProviderData: 'Los datos del proveedor no son válidos.',
      invalidProviderId: 'El ID del proveedor no es válido.',
      invalidOauthResponseType: 'Solo se permite un tipo de respuesta OAuth.',
      invalidSessionCookieDuration:
        'La duración de la cookie de sesión no es válida.',
      invalidUid: 'La ID de usuario proporcionada no es válida.',
      invalidUserImport:
        'El registro de usuario para importación no es válido.',
      maximumUserCountExceeded: 'Se ha superado el número máximo de usuarios.',
      missingAndroidPkgName: 'Se requiere el nombre del paquete Android.',
      missingContinueUri: 'Se requiere una URL de continuación válida.',
      missingHashAlgorithm:
        'Se requiere el algoritmo de hash para la importación.',
      missingIosBundleId: 'Falta el ID del paquete iOS.',
      missingUid: 'Se requiere una ID de usuario para esta operación.',
      missingOauthClientSecret: 'Falta el secreto del cliente OAuth.',
      operationNotAllowed: 'Esta operación no está permitida.',
      phoneNumberAlreadyExists: 'Este número de teléfono ya está en uso.',
      projectNotFound: 'No se encontró ningún proyecto de Firebase.',
      reservedClaims: 'Uno o más reclamos personalizados están reservados.',
      sessionCookieExpired: 'La cookie de sesión ha expirado.',
      sessionCookieRevoked: 'La cookie de sesión ha sido revocada.',
      tooManyRequests: 'Demasiadas solicitudes. Inténtalo más tarde.',
      uidAlreadyExists: 'Esta ID de usuario ya está en uso.',
      unauthorizedContinueUri: 'La URL de continuación no está autorizada.',
      userNotFound: 'No se encontró un usuario para este identificador.',
      cancelledPopupRequest:
        'Cancelaste el inicio de sesión con Google. Intenta de nuevo.',
      generic: 'Ocurrió un error. Inténtalo de nuevo.'
    }
  }
}
