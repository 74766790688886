<template>
  <div
    :class="[
      'card',
      {
        active: router.currentRoute.value.query.sound === props.soundId,
        playing: isPlaying && store.isPlaying,
        favorite: isFavorite
      }
    ]"
    @click="handleCardClick"
  >
    <button class="card__header">
      <img
        class="card__header-image"
        loading="lazy"
        :src="imageSrc"
        :alt="title"
        @load="imageLoaded"
        @error="imageLoadFailed"
      />

      <Spinner v-if="isLoading" class="card_header-image-spinner" />
      <Spinner
        v-if="isPlaying && store.isPlaying"
        type="bars"
        :size="60"
        class="card_header-image-spinner"
      />
      <Spinner
        v-if="isPlaying && !store.isPlaying"
        type="stop"
        :size="60"
        class="card_header-image-spinner"
      />

      <span
        v-if="isNew && router.currentRoute.value.query.filter !== 'favorites'"
        class="card__label card__label--new"
        :style="{
          transform: `rotate(45deg) translate(${labelTranslateX}, -16px)`
        }"
      >
        <Text is="h6">{{ t('card.new') }}</Text>
      </span>

      <div class="card__labels">
        <div
          v-for="(label, labelIndex) in labels"
          :key="labelIndex"
          class="card__label"
        >
          <Text is="p4">{{ label }}</Text>
        </div>
      </div>

      <div class="card__icons">
        <Icon
          class="card__icon card__icon--collection"
          :icon="`collection-${type}`"
        />
        <Icon
          v-for="(icon, iconIndex) in icons"
          :key="iconIndex"
          class="card__icon"
          :icon="icon"
        />
        <Icon
          v-if="requiresPlan && !store.userHasSubscription"
          class="card__icon card__icon--lock"
          icon="lock"
        />

        <Icon
          v-if="isFavorite"
          icon="favorite"
          :style="{ color: 'var(--color-accent-50)' }"
          @click.stop="toggleFavorite"
          class="card__icon"
        />
        <Icon
          v-else
          icon="favorite-outlined"
          @click.stop="toggleFavorite"
          class="card__icon"
        />
      </div>
    </button>

    <footer v-if="title || details" class="card__footer">
      <Text v-if="title" is="h5" class="card__footer-title">{{ title }}.</Text>
      <Text v-if="details" is="p3">{{ details }}.</Text>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import router from '@/router'
import { useMainStore } from '@/state/index'
import { lang } from '@/services/i18n'
import { useI18n } from 'vue-i18n'
import Prices from '@/components/Prices.vue'

defineOptions({
  name: 'CardComponent'
})

const { t } = useI18n()
const store = useMainStore()
const props = withDefaults(
  defineProps<{
    soundId: string
    imageId: string
    title: string
    details: string
    labels: string[]
    icons: string[]
    isNew: false
    type: string
    requiresPlan: boolean
  }>(),
  {}
)

const labelTranslateX = computed(() => {
  return lang === 'es' ? '30px' : '25px'
})

function trimLanguageSuffix(soundId: string) {
  return soundId.replace(/-(en|es)$/, '')
}

const isPlaying = computed(() => {
  return store.playingSound?.[lang]?.soundId === props.soundId
})

const isLoading = ref(true)
const imageSrc = ref(`/assets/images/${props.imageId}.webp`)

function imageLoaded() {
  isLoading.value = false
}

function imageLoadFailed() {
  isLoading.value = false
  console.error('Failed to load image')
}

const isFavorite = computed(() =>
  store.userFavorites.includes(trimLanguageSuffix(props.soundId))
)

const toggleFavorite = async () => {
  const soundId = trimLanguageSuffix(props.soundId)
  const isCurrentlyFavorite = store.userFavorites.includes(soundId)

  await store.toggleFavorite(soundId)

  if (isCurrentlyFavorite) {
    store.setGlobalToast({
      visible: true,
      title: t('toast.favorites.removedFromFavorites'),
      message: `${props.title} ${t('toast.favorites.removedFromFavoritesMessage')}`,
      type: 'warning'
    })
  } else {
    store.setGlobalToast({
      visible: true,
      title: t('toast.favorites.addedToFavorites'),
      message: `${props.title} ${t('toast.favorites.addedToFavoritesMessage')}`,
      type: 'success'
    })
  }
}

function handleCardClick() {
  if (props.requiresPlan && !store.userHasSubscription) {
    store.setGlobalModal({
      visible: true,
      title: `${props.title}.`,
      subtitle: t('modal.premium.subtitle'),
      body: t('modal.premium.body'),
      component: Prices
    })
  } else {
    router.push({
      query: {
        ...router.currentRoute.value.query,
        sound: props.soundId
      }
    })
  }
}
</script>

<style scoped lang="scss">
.card {
  position: relative;
  cursor: pointer;

  &:hover {
    .card__header-image {
      transform: scale(1.1);
      transform-origin: center;
    }

    .card__header {
      border-color: var(--color-link-hover);
    }
  }

  &.active,
  &.favorite {
    .card__header {
      border-color: var(--color-accent-50);
    }
  }

  &.playing {
    .card__header {
      border-color: var(--color-accent-50);
      box-shadow: 0;
      animation: pulse 1.618s infinite;
    }
  }
}

.card__header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: var(--size-card-header-min-size);
  min-height: var(--size-card-header-min-size);
  margin-bottom: var(--size-40);
  border: var(--size-1) solid var(--color-neutral-70);
  border-radius: var(--size-30);
  overflow: hidden;
  transition: 0.3s ease-in-out;
  background-color: transparent;
  padding: 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0;
  }

  50% {
    box-shadow:
      0 0 var(--size-120) var(--color-neutral-80-2-inverted),
      0 0 var(--size-120) var(--color-neutral-80-2-inverted);
  }

  100% {
    box-shadow: 0;
  }
}

.card__header-image {
  object-fit: cover;
  width: 100%;
  min-width: inherit;
  min-height: inherit;
  border-radius: var(--size-20);
  box-shadow: 0 var(--size-10) var(--size-10) 0 rgba(18, 18, 18, 0.24);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.card_header-image-spinner {
  position: absolute;
}

.card__labels,
.card__icons {
  display: flex;
  gap: var(--size-10);
  position: absolute;
  bottom: var(--size-20);
  right: var(--size-20);

  .icon--favorite,
  .icon--favorite-outlined {
    cursor: pointer;
    font-size: var(--font-size-40);
    background-color: var(--color-neutral-80);
  }

  &:hover {
    .icon--favorite,
    .icon--favorite-outlined {
      transform: scale(1.1);
      transform-origin: center;
      transition: 0.3s ease-in-out;
    }
  }
}

.card__icons {
  align-items: flex-end;
}

.card__labels {
  flex-wrap: wrap;
  width: calc(100% - var(--size-150));
  bottom: initial;
  right: initial;
  top: var(--size-20);
  left: var(--size-20);
}

.card__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-body-text);
  background-color: var(--color-neutral-80-48);
  border-radius: var(--size-radius-round);
  width: var(--size-80);
  height: var(--size-80);

  &--lock {
    color: var(--color-warning-50);
  }

  &--collection {
    color: var(--color-neutral-30);
  }
}

.card__label {
  display: inline-flex;
  text-align: left;
  color: var(--color-body-text);
  background-color: var(--color-body-bg);
  border-radius: var(--size-10);
  padding: var(--size-10) var(--size-20);

  &--new {
    position: absolute;
    top: 0;
    right: 0;
    padding: var(--size-10) var(--size-80);
    border-radius: 0;
    transform: rotate(45deg) translate(27px, -16px);
    background-color: var(--color-accent-50);
    color: var(--color-neutral-90);
    text-align: center;
  }
}

.card__footer-title {
  display: block;
  margin: var(--size-40) 0 var(--size-20) 0;
}
</style>
