import { TypeSpaces } from './spaces'

export const Music: TypeSpaces = {
  'at-the-sea': {
    en: {
      labels: [],
      title: 'At the sea',
      details: '',
      soundId: 'at-the-sea'
    },
    es: {
      labels: [],
      title: 'En el mar',
      details: '',
      soundId: 'at-the-sea'
    },
    type: 'music',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: false,
    imageId: 'at-the-sea'
  },
  'good-morning': {
    en: {
      labels: [],
      title: 'Good morning',
      details: '',
      soundId: 'good-morning'
    },
    es: {
      labels: [],
      title: 'Buenos días',
      details: '',
      soundId: 'good-morning'
    },
    type: 'music',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: false,
    imageId: 'good-morning'
  },
  'mystic-jungle': {
    en: {
      labels: [],
      title: 'Mystic jungle',
      details: '',
      soundId: 'mystic-jungle'
    },
    es: {
      labels: [],
      title: 'Selva mística',
      details: '',
      soundId: 'mystic-jungle'
    },
    type: 'music',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mystic-jungle'
  },
  'natural-intervention': {
    en: {
      labels: [],
      title: 'Natural intervention',
      details: '',
      soundId: 'natural-intervention'
    },
    es: {
      labels: [],
      title: 'Intervención natural',
      details: '',
      soundId: 'natural-intervention'
    },
    type: 'music',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'natural-intervention'
  },
  'rain-reflex': {
    en: {
      labels: [],
      title: 'Rain reflex',
      details: '',
      soundId: 'rain-reflex'
    },
    es: {
      labels: [],
      title: 'Reflejo de lluvia',
      details: '',
      soundId: 'rain-reflex'
    },
    type: 'music',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'rain-reflex'
  },
  'rain-on-piano': {
    en: {
      labels: [],
      title: 'Rain on piano',
      details: '',
      soundId: 'rain-on-piano'
    },
    es: {
      labels: [],
      title: 'Lluvia sobre el piano',
      details: '',
      soundId: 'rain-on-piano'
    },
    type: 'music',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'rain-on-piano'
  }
}
