<template>
  <div class="auth">
    <header class="auth-header">
      <div v-if="state === 'login'">
        <Text is="p3" centered>{{ t('auth.header.newTo') }}</Text>
        <button @click="updateState('signup')" class="auth-button-link">
          <Text is="h5">{{ t('auth.header.tryForFree') }}</Text>
        </button>
      </div>

      <div v-else-if="state === 'signup'">
        <Text is="p3" centered>{{ t('auth.header.alreadyHaveAccount') }}</Text>
        <button @click="updateState('login')" class="auth-button-link">
          <Text is="h5">{{ t('auth.header.logIn') }}</Text>
        </button>
      </div>
    </header>

    <div class="auth-form">
      <section class="auth-form__section auth-form__section--left">
        <Logo class="logo--desktop" />
        <br />
        <br />
        <div v-if="state === 'signup'">
          <Text>{{ t('authPrices.fullExperienceFor') }}</Text>
          <Text is="h1" inline :style="{ color: 'var(--color-accent-50)' }">
            €0
          </Text>
          <Text
            inline
            is="h3"
            :style="{
              marginLeft: 'var(--size-30)',
              color: 'var(--color-accent-50)'
            }"
          >
            {{ t('authPrices.today') }}
          </Text>
          <br />
          <Text v-if="yearlyPrice && monthlyPrice" is="p3">
            {{
              t('authPrices.priceDetails', {
                yearlyPricePerMonth,
                yearlyPrice,
                monthlyPrice
              })
            }}
          </Text>
          <br />
          <br />

          <Text>
            <Icon class="card__icon" icon="check" />
            {{ t('authPrices.accessCollection') }}
          </Text>
          <br />
          <Text>
            <Icon class="card__icon" icon="check" />
            {{ t('authPrices.noAds') }}
          </Text>
          <br />
          <Text>
            <Icon class="card__icon" icon="check" />
            {{ t('authPrices.accessTools') }}
          </Text>
          <br />
          <Text>
            <Icon class="card__icon" icon="check" />
            {{ t('authPrices.newContentDaily') }}
          </Text>
          <br />
          <Text>
            <Icon class="card__icon" icon="check" />
            {{ t('authPrices.cancelAnytime') }}
          </Text>
          <br />
          <br />

          <Text is="p3">{{ t('auth.signup.alreadyAccount') }}</Text>
          <button @click="updateState('login')" class="auth-button-link">
            <Text is="h5">{{ t('auth.header.logIn') }}</Text>
          </button>
        </div>

        <div v-else>
          <Text is="h2">{{ t('auth.login.welcomeBack') }}</Text>
          <br />
          <Text>{{ t('auth.login.readyToDive') }}</Text>
          <br />
          <br />
          <Text is="p3">{{ t('auth.header.newTo') }}</Text>
          <button @click="updateState('signup')" class="auth-button-link">
            <Text is="h5">{{ t('auth.header.tryForFree') }}</Text>
          </button>

          <br />
          <br />
          <br />
          <br />
          <br />

          <!-- <footer class="auth-footer">
            <Icon class="card__icon" icon="socials-youtube" :size="60" />
            <Icon class="card__icon" icon="socials-facebook" :size="60" />
            <Icon class="card__icon" icon="socials-twitter" :size="60" />
            <Icon class="card__icon" icon="socials-linkedin" :size="60" />
          </footer>

          <br /> -->

          <Text is="p3">{{ t('copyright') }}</Text>
        </div>
      </section>

      <section class="auth-form__section auth-form__section--right">
        <Logo class="logo--mobile" />
        <br />
        <component :is="currentComponent" @update-state="updateState" />
        <br />
        <br />

        <Text is="h5" centered>
          <Icon icon="minus" :size="40" />
          {{ t('auth.social.or') }}
          <Icon icon="minus" :size="40" />
        </Text>
        <br />

        <AuthSocials />
        <br />
      </section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineAsyncComponent, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useMainStore } from '@/state/index'
import { formatPrice } from '@/utils/functions'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'AuthComponent'
})

const { t } = useI18n()
const store = useMainStore()
const route = useRoute()
const router = useRouter()

const yearlyPricePerMonth = computed(() => {
  const yearlyPrice =
    store.availablePrices
      .flatMap((product) => product.prices)
      .find((price) => price.interval === 'year')?.unit_amount || null

  return yearlyPrice ? formatPrice(yearlyPrice / 12, 'EUR') : null
})

const yearlyPrice = computed(() => {
  const yearlyPrice =
    store.availablePrices
      .flatMap((product) => product.prices)
      .find((price) => price.interval === 'year')?.unit_amount || null

  return yearlyPrice ? formatPrice(yearlyPrice, 'EUR') : null
})

const monthlyPrice = computed(() => {
  const monthlyPrice =
    store.availablePrices
      .flatMap((product) => product.prices)
      .find((price) => price.interval === 'month')?.unit_amount || null

  return monthlyPrice ? formatPrice(monthlyPrice, 'EUR') : null
})

const state = ref<
  'login' | 'signup' | 'forgotPassword' | 'resetPassword' | 'verifyEmail'
>('login')

// Automatically set the state based on the current route
const setAuthStateFromRoute = () => {
  const path = route.path
  if (path.includes('login')) {
    state.value = 'login'
  } else if (path.includes('sign-up')) {
    state.value = 'signup'
  } else if (path.includes('forgot-password')) {
    state.value = 'forgotPassword'
  } else if (path.includes('reset-password')) {
    state.value = 'resetPassword'
  } else if (path.includes('verify-email')) {
    state.value = 'verifyEmail'
  }
}

// Update state manually and change route
const updateState = (
  newState:
    | 'login'
    | 'signup'
    | 'forgotPassword'
    | 'resetPassword'
    | 'verifyEmail'
) => {
  state.value = newState
  // Update the URL when state is manually changed
  if (newState === 'login') {
    router.push({ name: 'AuthLogin' })
  } else if (newState === 'signup') {
    router.push({ name: 'AuthSignUp' })
  } else if (newState === 'forgotPassword') {
    router.push({ name: 'AuthForgotPassword' })
  } else if (newState === 'resetPassword') {
    router.push({ name: 'AuthResetPassword' })
  } else if (newState === 'verifyEmail') {
    router.push({ name: 'AuthEmailVerify' })
  } else {
    router.push('/')
  }
}

// Watch for route changes
onMounted(() => {
  setAuthStateFromRoute()
})

watch(route, () => {
  setAuthStateFromRoute()
})

// Dynamically load components based on state
const currentComponent = computed(() => {
  if (state.value === 'login') {
    return defineAsyncComponent(() => import('@/components/AuthLogin.vue'))
  } else if (state.value === 'signup') {
    return defineAsyncComponent(() => import('@/components/AuthSignUp.vue'))
  } else if (state.value === 'forgotPassword') {
    return defineAsyncComponent(
      () => import('@/components/AuthForgotPassword.vue')
    )
  } else if (state.value === 'verifyEmail') {
    return defineAsyncComponent(
      () => import('@/components/AuthEmailVerify.vue')
    )
  } else {
    return defineAsyncComponent(
      () => import('@/components/AuthResetPassword.vue')
    )
  }
})
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100svw;
  height: 100svh;
  position: fixed;
  background-color: var(--color-auth-overlay);

  &::before {
    content: '';
    background-image: url('../../public/assets/images/main-background.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: var(--z-index-behind);
  }

  &::after {
    content: '';
    width: inherit;
    height: inherit;
    position: absolute;
    background-color: var(--color-auth-overlay);
  }
}

.auth-form {
  display: grid;
  grid-template-columns: 1fr; // Stacked layout on small screens
  gap: var(--size-60);
  padding: var(--size-40);
  overflow-y: scroll;
  z-index: var(--z-index-low);

  @media (min-width: $device-xs) {
    padding: var(--size-60);
  }

  @media (min-width: $device-sm) {
    padding: var(--size-80);
  }

  @media (min-width: $device-md) {
    padding: var(--size-100);
  }

  @media (min-width: $device-lg) {
    padding: var(--size-120);
  }

  @media (min-width: $device-xl) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.auth-form__section {
  padding: var(--size-80);
  border-radius: var(--size-60);
  width: 100%;
  max-width: var(--size-auth-section-width);

  &--right {
    background-color: var(--color-body-bg);
    box-shadow: var(--color-shadow) 0 var(--size-20) var(--size-40) 0;
    order: -1;

    @media (min-width: $device-xl) {
      order: 0;
    }
  }
}

.auth-header {
  display: block;
  padding: var(--size-40);
  z-index: var(--z-index-low);

  @media (min-width: $device-xl) {
    display: none;
  }
}

.logo--mobile {
  display: block;

  @media (min-width: $device-xl) {
    display: none;
  }
}

.logo--desktop {
  display: none;

  @media (min-width: $device-xl) {
    display: block;
    margin-left: -16px;
  }
}

.auth-button-link {
  background-color: transparent;
  color: var(--color-body-text);
  padding: var(--size-10) 0;
  border: 0;
  outline: 0;
  border-bottom: var(--size-5) solid var(--color-neutral-10);
  margin-top: var(--size-10);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: var(--color-accent-50);
    color: var(--color-accent-50);
  }
}

.auth-footer {
  display: flex;
  gap: var(--size-40);

  .icon {
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      color: var(--color-accent-50);
    }
  }
}
</style>
