<template>
  <div class="spinner">
    <div
      v-if="type === 'bars' || type === 'stop'"
      :class="['bars', { 'bars--stop': type === 'stop' }]"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
        gap: `${size / 8}px`
      }"
    >
      <span />
      <span />
      <span />
    </div>

    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 104 104"
      preserveAspectRatio="xMidYMid"
      :width="size"
      :height="size"
      style="shape-rendering: auto; display: block; background: transparent"
    >
      <circle
        cx="48"
        cy="48"
        fill="none"
        stroke-width="8"
        r="32"
        stroke-dasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur="1s"
          values="0 48 48;360 48 48"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SpinnerComponent'
}
</script>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    size?: number
    type?: null | 'bars' | 'stop'
  }>(),
  {
    size: 32
  }
)
</script>

<style scoped lang="scss">
.spinner {
  svg {
    circle {
      stroke: var(--color-accent-50);
    }
  }
}

.bars {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-spinner-bg);
  border-radius: var(--size-radius-round);
  padding: 24%;

  span {
    width: inherit;
    height: 100%;
    background-color: var(--color-accent-50);
    border-radius: var(--size-100);
    transform-origin: bottom;
    animation: bounce 2.2s ease infinite alternate;

    &:nth-of-type(2) {
      animation-delay: -2.2s; /* Start at the end of animation */
    }

    &:nth-of-type(3) {
      animation-delay: -3.7s; /* Start mid-way of return of animation */
    }
  }

  &--stop {
    span {
      border-radius: var(--size-radius-round);
      height: var(--size-20);
      width: var(--size-20);
      animation: none;
      transform: scaleY(1);

      &:first-of-type,
      &:last-of-type {
        display: none;
      }
    }
  }
}

@keyframes bounce {
  10% {
    transform: scaleY(0.24); /* start by scaling to 30% */
  }

  30% {
    transform: scaleY(1); /* scale up to 100% */
  }

  60% {
    transform: scaleY(0.56); /* scale down to 50% */
  }

  80% {
    transform: scaleY(0.72); /* scale up to 75% */
  }

  100% {
    transform: scaleY(0.64); /* scale down to 60% */
  }
}
</style>
