import { TypeSpaces } from './spaces'

export const Urban: TypeSpaces = {
  'good-morning-farm': {
    en: {
      labels: [],
      title: 'Good morning farm',
      details: '',
      soundId: 'good-morning-farm'
    },
    es: {
      labels: [],
      title: 'Buenos días granja',
      details: '',
      soundId: 'good-morning-farm'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'good-morning-farm'
  },
  'morning-at-the-lake': {
    en: {
      labels: [],
      title: 'Morning at the lake',
      details: '',
      soundId: 'morning-at-the-lake'
    },
    es: {
      labels: [],
      title: 'Mañana en el lago',
      details: '',
      soundId: 'morning-at-the-lake'
    },
    type: 'urban',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: false,
    imageId: 'morning-at-the-lake'
  },
  'mountain-highway': {
    en: {
      labels: [],
      title: 'Mountain highway',
      details: '',
      soundId: 'mountain-highway'
    },
    es: {
      labels: [],
      title: 'Autopista de montaña',
      details: '',
      soundId: 'mountain-highway'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'mountain-highway'
  },
  'rain-undercover': {
    en: {
      labels: [],
      title: 'Rain undercover',
      details: '',
      soundId: 'rain-undercover'
    },
    es: {
      labels: [],
      title: 'Lluvia encubierta',
      details: '',
      soundId: 'rain-undercover'
    },
    type: 'urban',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: false,
    imageId: 'rain-undercover'
  },
  'rain-and-thunder': {
    en: {
      labels: [],
      title: 'Rain and thunder',
      details: '',
      soundId: 'rain-and-thunder'
    },
    es: {
      labels: [],
      title: 'Lluvia y trueno',
      details: '',
      soundId: 'rain-and-thunder'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'rain-and-thunder'
  },
  'thunder-wonders': {
    en: {
      labels: [],
      title: 'Thunder wonders',
      details: '',
      soundId: 'thunder-wonders'
    },
    es: {
      labels: [],
      title: 'Maravillas del trueno',
      details: '',
      soundId: 'thunder-wonders'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'thunder-wonders'
  },
  'waiting-on-the-jungle-road': {
    en: {
      labels: [],
      title: 'Waiting on the jungle road',
      details: '',
      soundId: 'waiting-on-the-jungle-road'
    },
    es: {
      labels: [],
      title: 'Esperando en el camino de la jungla',
      details: '',
      soundId: 'waiting-on-the-jungle-road'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: false,
    imageId: 'waiting-on-the-jungle-road'
  },
  'brown-noise': {
    en: {
      labels: [],
      title: 'Brown noise',
      details: '',
      soundId: 'brown-noise'
    },
    es: {
      labels: [],
      title: 'Ruido marrón',
      details: '',
      soundId: 'brown-noise'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'brown-noise'
  },
  'newborn-crying': {
    en: {
      labels: [],
      title: 'Newborn crying',
      details: '',
      soundId: 'newborn-crying'
    },
    es: {
      labels: [],
      title: 'Recién nacido llorando',
      details: '',
      soundId: 'newborn-crying'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'newborn-crying'
  },
  'pink-noise': {
    en: {
      labels: [],
      title: 'Pink noise',
      details: '',
      soundId: 'pink-noise'
    },
    es: {
      labels: [],
      title: 'Ruido rosa',
      details: '',
      soundId: 'pink-noise'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'pink-noise'
  },
  'spaces-fm-noise': {
    en: {
      labels: [],
      title: 'Spaces.fm noise',
      details: '',
      soundId: 'spaces-fm-noise'
    },
    es: {
      labels: [],
      title: 'Ruido Spaces.fm',
      details: '',
      soundId: 'spaces-fm-noise'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'spaces-fm-noise'
  },
  'white-noise': {
    en: {
      labels: [],
      title: 'White noise',
      details: '',
      soundId: 'white-noise'
    },
    es: {
      labels: [],
      title: 'Ruido blanco',
      details: '',
      soundId: 'white-noise'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'white-noise'
  },
  'cowbells-in-the-background': {
    en: {
      labels: [],
      title: 'Cowbells in the background',
      details: '',
      soundId: 'cowbells-in-the-background'
    },
    es: {
      labels: [],
      title: 'Cencerros en el fondo',
      details: '',
      soundId: 'cowbells-in-the-background'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'cowbells-in-the-background'
  },
  'distant-cowbells': {
    en: {
      labels: [],
      title: 'Distant cowbells',
      details: '',
      soundId: 'distant-cowbells'
    },
    es: {
      labels: [],
      title: 'Cencerros lejanos',
      details: '',
      soundId: 'distant-cowbells'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'distant-cowbells'
  },
  'seaside-retreat': {
    en: {
      labels: [],
      title: 'Seaside retreat',
      details: '',
      soundId: 'seaside-retreat'
    },
    es: {
      labels: [],
      title: 'Retiro junto al mar',
      details: '',
      soundId: 'seaside-retreat'
    },
    type: 'urban',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'seaside-retreat'
  }
}
