import { TypeSpaces } from './spaces'

export const Sessions: TypeSpaces = {
  'how-to-learn-anything': {
    en: {
      labels: ['Session inspired by Josh Kaufman', '4:48 min.'],
      title: 'How to learn anything',
      details:
        'Discover how to master any new skill in just 20 hours of focused practice — overcome time constraints and self-doubt to unlock your potential',
      soundId: 'how-to-learn-anything-en'
    },
    es: {
      labels: ['Sesión inspirada por Josh Kaufman', '4:48 min.'],
      title: 'Cómo aprender cualquier cosa',
      details:
        'Descubre cómo dominar cualquier habilidad en solo 20 horas de práctica enfocada — supera las limitaciones de tiempo y la autocrítica para desbloquear tu potencial',
      soundId: 'how-to-learn-anything-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'how-to-learn-anything'
  },
  'inside-the-mind-of-a-procrastinator': {
    en: {
      labels: ['Session inspired by Tim Urban', '4:08 min.'],
      title: 'Inside the mind of a procrastinator',
      details:
        'Explore the inner workings of procrastination — uncover the battle between instant gratification and rational decision-making, and learn how to break the cycle of last-minute rushes to regain control of your time',
      soundId: 'inside-the-mind-of-a-procrastinator-en'
    },
    es: {
      labels: ['Sesión inspirada por Tim Urban', '4:08 min.'],
      title: 'Dentro de la mente de un procrastinador',
      details:
        'Explora la mente del procrastinador — descubre la lucha entre la gratificación instantánea y la toma de decisiones racionales, y aprende a romper el ciclo de prisas de última hora para retomar el control de tu tiempo',
      soundId: 'inside-the-mind-of-a-procrastinator-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'inside-the-mind-of-a-procrastinator'
  },
  'start-with-why': {
    en: {
      labels: ['Session inspired by Simon Sinek', '5:34 min.'],
      title: 'Start with why',
      details:
        'Uncover the power of purpose by exploring why some leaders and organizations inspire while others don\'t — learn to lead by starting with "why."',
      soundId: 'start-with-why-en'
    },
    es: {
      labels: ['Sesión inspirada por Simon Sinek', '5:34 min.'],
      title: 'Empieza con el porqué',
      details:
        'Descubre el poder del propósito al explorar por qué algunos líderes y organizaciones inspiran y otros no — aprende a liderar comenzando con el "por qué."',
      soundId: 'start-with-why-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'start-with-why'
  },
  'the-easy-way-to-quit-smoking': {
    en: {
      labels: ['Session inspired by Allen Carr', '4:50 min.'],
      title: 'The easy way to quit smoking',
      details:
        'Discover a simple, effective, and liberating approach to quit smoking — change your mindset and free yourself from addiction',
      soundId: 'the-easy-way-to-quit-smoking-en'
    },
    es: {
      labels: ['Sesión inspirada por Allen Carr', '4:50 min.'],
      title: 'La manera fácil de dejar de fumar',
      details:
        'Descubre un enfoque simple, efectivo y liberador para dejar de fumar — cambia tu mentalidad y libérate de la adicción',
      soundId: 'the-easy-way-to-quit-smoking-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'the-easy-way-to-quit-smoking'
  },
  'the-pale-blue-dot': {
    en: {
      labels: ['Session inspired by Carl Sagan', '4:08 min.'],
      title: 'The pale blue dot',
      details:
        'Explore the vastness of the cosmos through the lens of Carl Sagan — reflect on our place in the universe and the importance of protecting our fragile planet',
      soundId: 'the-pale-blue-dot-en'
    },
    es: {
      labels: ['Sesión inspirada por Carl Sagan', '4:08 min.'],
      title: 'El punto azul pálido',
      details:
        'Explora la inmensidad del cosmos a través de la visión de Carl Sagan — reflexiona sobre nuestro lugar en el universo y la importancia de proteger nuestro frágil planeta',
      soundId: 'the-pale-blue-dot-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'the-pale-blue-dot'
  },
  'the-power-of-consciousness': {
    en: {
      labels: ['Session inspired by Jacobo Grinberg', '7:44 min.'],
      title: 'The power of consciousness',
      details:
        'Explore the vast potential of consciousness — understand its power to shape reality, foster deeper connections, and transform your life',
      soundId: 'the-power-of-consciousness-en'
    },
    es: {
      labels: ['Sesión inspirada por Jacobo Grinberg', '7:44 min.'],
      title: 'El poder de la conciencia',
      details:
        'Explora el vasto potencial de la conciencia — comprende su poder para moldear la realidad, fomentar conexiones más profundas y transformar tu vida',
      soundId: 'the-power-of-consciousness-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: true,
    imageId: 'the-power-of-consciousness'
  },
  'the-power-of-mindfulness': {
    en: {
      labels: ['Session inspired by Andy Puddicombe', '6:08 min.'],
      title: 'The power of mindfulness',
      details:
        'Discover how mindfulness can transform your life — take just 10 minutes a day to be present, reduce stress, and find clarity',
      soundId: 'the-power-of-mindfulness-en'
    },
    es: {
      labels: ['Sesión inspirada por Andy Puddicombe', '6:08 min.'],
      title: 'El poder de la atención plena',
      details:
        'Descubre cómo la atención plena puede transformar tu vida — dedica solo 10 minutos al día para estar presente, reducir el estrés y encontrar claridad',
      soundId: 'the-power-of-mindfulness-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'the-power-of-mindfulness'
  },
  'the-power-of-now': {
    en: {
      labels: ['Session inspired by Eckhart Tolle', '4:00 min.'],
      title: 'The power of now',
      details:
        'Discover the importance of living in the present moment — learn how embracing the now can lead to true happiness and fulfillment',
      soundId: 'the-power-of-now-en'
    },
    es: {
      labels: ['Sesión inspirada por Eckhart Tolle', '4:00 min.'],
      title: 'El poder del ahora',
      details:
        'Descubre la importancia de vivir en el momento presente — aprende cómo abrazar el ahora puede llevar a la verdadera felicidad y realización',
      soundId: 'the-power-of-now-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: false,
    favorite: false,
    new: true,
    imageId: 'the-power-of-now'
  },
  'the-power-of-resilience-and-determination': {
    en: {
      labels: [
        "Session inspired by Masashi Kishimoto's Naruto animated series",
        '5:36 min.'
      ],
      title: 'The power of resilience and determination',
      details:
        'Discover how resilience and determination can help you overcome any obstacle — embrace your strengths, keep pushing forward, and unlock your true potential',
      soundId: 'the-power-of-resilience-and-determination-en'
    },
    es: {
      labels: [
        'Sesión inspirada por la serie animada Naruto de Masashi Kishimoto',
        '5:36 min.'
      ],
      title: 'El poder de la resiliencia y la determinación',
      details:
        'Descubre cómo la resiliencia y la determinación pueden ayudarte a superar cualquier obstáculo — abraza tus fortalezas, sigue adelante y desbloquea tu verdadero potencial',
      soundId: 'the-power-of-resilience-and-determination-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'the-power-of-resilience-and-determination'
  },
  'the-power-of-thought': {
    en: {
      labels: ['Session inspired by Joe Dispenza', '5:36 min.'],
      title: 'The power of thought',
      details:
        'Explore how your thoughts shape your reality — learn to harness the power of your mind to create a life aligned with your true desires',
      soundId: 'the-power-of-thought-en'
    },
    es: {
      labels: ['Sesión inspirada por Joe Dispenza', '5:36 min.'],
      title: 'El poder del pensamiento',
      details:
        'Explora cómo tus pensamientos moldean tu realidad — aprende a aprovechar el poder de tu mente para crear una vida alineada con tus verdaderos deseos',
      soundId: 'the-power-of-thought-es'
    },
    type: 'session',
    icons: [],
    requiresPlan: true,
    favorite: false,
    new: true,
    imageId: 'the-power-of-thought'
  }
}
