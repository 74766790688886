<template>
  <transition name="toast">
    <div
      v-if="visible"
      :class="['toast', `toast--${placement}`]"
      @mouseenter="pauseHideTimeout"
      @mouseleave="resumeHideTimeout"
    >
      <Icon
        icon="x"
        v-if="!hideCloseButton"
        @click="emitClose"
        class="toast__close-button"
      />

      <div
        v-if="title || message || $slots.default"
        :class="['toast__container', `toast__container--${type}`]"
      >
        <Text v-if="title" is="h5" class="toast__container-title">
          {{ title }}
        </Text>
        <Text v-if="message" is="p3" class="toast__container-message">
          {{ message }}
        </Text>
        <section v-if="$slots.default" class="toast__container-content">
          <slot />
        </section>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { ref, watch, onBeforeUnmount, onMounted } from 'vue'

defineOptions({
  name: 'ToastComponent'
})

// Timeout for hiding the toast
let hideTimeout: ReturnType<typeof setTimeout> | null = null
const remainingTime = ref(3200) // Store remaining time in ref
let startTime = 0

// Define props with default values
const props = withDefaults(
  defineProps<{
    title: string
    message: string
    hideCloseButton?: boolean
    type?: 'success' | 'warning'
    visible: boolean
    placement?:
      | 'bottom-left'
      | 'bottom-center'
      | 'bottom-right'
      | 'top-left'
      | 'top-center'
      | 'top-right' // Placement prop
    persist?: boolean // Prop to control if the toast should persist
  }>(),
  {
    placement: 'bottom-left', // Default placement
    persist: false // Default persist is false, meaning the toast will auto-hide
  }
)

// Define emits
const emit = defineEmits<{
  (event: 'close'): void
}>()

// Emit close event
const emitClose = () => {
  emit('close')
  clearToastTimeout() // Ensure timeout is cleared when manually closed
}

// Function to clear existing timeout
const clearToastTimeout = () => {
  if (hideTimeout) {
    clearTimeout(hideTimeout)
    hideTimeout = null
  }
}

// Function to start or resume the hide timeout
const startHideTimeout = (duration: number) => {
  clearToastTimeout()
  hideTimeout = setTimeout(() => {
    emitClose()
  }, duration)
}

// Function to pause the hide timeout
const pauseHideTimeout = () => {
  if (!props.persist) {
    clearToastTimeout()
    remainingTime.value -= Date.now() - startTime // Calculate remaining time
  }
}

// Function to resume the hide timeout
const resumeHideTimeout = () => {
  if (!props.persist && remainingTime.value > 0) {
    startTime = Date.now() // Save the current time
    startHideTimeout(remainingTime.value) // Restart timeout with remaining time
  }
}

// Watch for changes in visible prop and auto-hide if persist is not set
watch(
  () => props.visible,
  (newVal) => {
    clearToastTimeout() // Clear any previous timeouts
    if (newVal && !props.persist) {
      remainingTime.value = 3200 // Reset the remaining time
      startTime = Date.now()
      startHideTimeout(remainingTime.value) // Start the auto-hide timeout
    }
  },
  { immediate: true }
)

// Clear any timeouts when the component is unmounted
onBeforeUnmount(() => {
  clearToastTimeout()
})

onMounted(() => {
  clearToastTimeout()
})
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  max-width: 480px;
  padding: var(--size-30);
  z-index: var(--z-index-top);
  width: auto;

  /* Bottom Left */
  &--bottom-left {
    bottom: 0;
    left: 0;
  }

  /* Bottom Center */
  &--bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Bottom Right */
  &--bottom-right {
    bottom: 0;
    right: 0;
  }

  /* Top Left */
  &--top-left {
    top: 0;
    left: 0;
  }

  /* Top Center */
  &--top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Top Right */
  &--top-right {
    top: 0;
    right: 0;
  }
}

.toast__container {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  position: relative;
  border-radius: var(--size-30);
  padding: var(--size-40);
  background-color: var(--color-neutral-90);
  color: var(--color-neutral-10);
  transition: opacity 0.3s ease-in-out;
  border: 1px solid var(--color-neutral-70);
  text-align: left;

  &--success {
    .toast__container-title {
      color: var(--color-accent-50);
    }
  }

  &--warning {
    .toast__container-title {
      color: var(--color-warning-50);
    }
  }
}

.toast__container-title,
.toast__container-message {
  padding-right: var(--size-60);
  color: var(--color-body-text);
}

.toast__container-content {
  border-top: 1px solid var(--color-neutral-70);
  margin-top: var(--size-20);
  padding: var(--size-60) 0 var(--size-10) 0;
}

.toast__close-button {
  position: absolute;
  top: var(--size-50);
  right: var(--size-60);
  cursor: pointer;
  transition: color 0.03s ease-in-out;
  z-index: var(--z-index-high);

  &:hover {
    color: var(--color-warning-50);
  }
}

.toast-enter {
  transform: scale(1.1);
  opacity: 0;
}

.toast-leave-active {
  transform: scale(1.1);
  opacity: 0;
}
</style>
