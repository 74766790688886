<template>
  <div class="auth">
    <div class="auth-form">
      <section class="auth-form__section auth-form__section--left">
        <!-- Hide this logo on mobile -->
        <Logo class="logo--desktop" />
        <br />
        <br />
        <Text is="h2">{{ t('authEmailVerify.almostThere') }}</Text>
        <br />
        <Text>{{ t('authEmailVerify.message') }}</Text>

        <br />
        <br />
        <br />
        <br />
        <br />

        <!-- <footer class="auth-footer">
          <Icon class="card__icon" icon="socials-youtube" :size="60" />
          <Icon class="card__icon" icon="socials-facebook" :size="60" />
          <Icon class="card__icon" icon="socials-twitter" :size="60" />
          <Icon class="card__icon" icon="socials-linkedin" :size="60" />
        </footer>

        <br /> -->

        <Text is="p3">{{ t('copyright') }}</Text>
      </section>

      <section class="auth-form__section auth-form__section--right">
        <!-- Show this logo on mobile -->
        <Logo class="logo--mobile" />
        <br />
        <Text is="h2">{{ t('authEmailVerify.verifyEmail') }}</Text>
        <br />
        <Text>{{ t('authEmailVerify.checkInbox') }}</Text>
        <br />

        <!-- Show spinner while loading email status or sending email -->
        <Spinner v-if="isLoading" />

        <!-- Success or Error Messages -->
        <Text v-if="emailVerifiedMessage" class="auth-success">
          {{ emailVerifiedMessage }}
        </Text>
        <Text v-if="emailSentMessage" class="auth-success">
          {{ emailSentMessage }}
        </Text>
        <Text v-if="verificationErrorMessage" class="auth-error">
          {{ verificationErrorMessage }}
        </Text>
        <Text v-if="errorMessage" class="auth-error">{{ errorMessage }}</Text>
        <br />

        <!-- Button to check verification status -->
        <button
          @click="checkEmailVerification"
          class="auth-button"
          :disabled="isLoading"
        >
          <Text is="h5">{{ t('authEmailVerify.checkStatus') }}</Text>
        </button>

        <br /><br /><br /><br />

        <!-- Helpful message -->
        <Text>{{ t('authEmailVerify.didntReceiveEmail') }}</Text>

        <!-- Button to resend verification email -->
        <button
          @click="resendVerificationEmail"
          class="auth-button auth-button--secondary"
          :disabled="isLoading"
        >
          <Text is="h5">{{ t('authEmailVerify.resendEmail') }}</Text>
        </button>
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onUnmounted, Ref } from 'vue'
import { auth } from '@/services/firebase'
import { reload, sendEmailVerification, AuthError } from 'firebase/auth'
import Logo from '@/components/Logo.vue'
import { useI18n } from 'vue-i18n'
import { useError } from '@/hooks/useError'

defineOptions({
  name: 'AuthEmailVerifyComponent'
})

const { t } = useI18n()
const { getFirebaseErrorMessage } = useError()

// States for messages, loading, and managing timeouts
const emailSentMessage = ref<string>('')
const emailVerifiedMessage = ref<string>('') // Display "Email Verified!" message
const errorMessage = ref<string>('')
const verificationErrorMessage = ref<string>('') // For verification status error
const isLoading = ref<boolean>(false) // Spinner control
const emit = defineEmits(['email-verified'])

let timeoutId: ReturnType<typeof setTimeout> | null = null // Store timeout ID for clearing

// Utility function to set and auto-clear messages
const setMessage = (
  messageRef: Ref<string>,
  message: string,
  duration = 3000
) => {
  if (timeoutId) clearTimeout(timeoutId as ReturnType<typeof setTimeout>)

  messageRef.value = message
  timeoutId = setTimeout(() => {
    messageRef.value = ''
    timeoutId = null
  }, duration)
}

// Clear any active timeouts when the component is destroyed
onUnmounted(() => {
  if (timeoutId) clearTimeout(timeoutId as ReturnType<typeof setTimeout>)
})

// Resend the verification email
const resendVerificationEmail = async () => {
  isLoading.value = true
  try {
    const user = auth.currentUser
    if (user) {
      await sendEmailVerification(user)
      setMessage(emailSentMessage, t('authEmailVerify.resendEmailSuccess'))
    } else {
      setMessage(errorMessage, t('errors.auth.noUserSignedIn'))
    }
  } catch (error) {
    if (error instanceof Error && 'code' in error) {
      setMessage(
        errorMessage,
        getFirebaseErrorMessage((error as AuthError).code)
      )
    } else {
      setMessage(errorMessage, t('errors.auth.generic'))
    }
    console.error('Error resending verification email:', error)
  } finally {
    isLoading.value = false
  }
}

// Check if the email is verified
const checkEmailVerification = async () => {
  const timeBeforeReloading = 1000

  isLoading.value = true
  try {
    const user = auth.currentUser
    if (user) {
      await reload(user)
      if (user.emailVerified) {
        setMessage(emailVerifiedMessage, t('authEmailVerify.emailVerified'))
        setTimeout(async () => {
          await emit('email-verified')
          window.location.reload()
        }, timeBeforeReloading)
      } else {
        setMessage(
          verificationErrorMessage,
          t('authEmailVerify.emailNotVerified')
        )
      }
    } else {
      setMessage(errorMessage, t('errors.auth.noUserSignedIn'))
    }
  } catch (error) {
    // Ensure the error is an object with a 'code' property
    if (error instanceof Error && 'code' in error) {
      const firebaseError = error as { code: string }
      setMessage(
        verificationErrorMessage,
        getFirebaseErrorMessage(firebaseError.code)
      )
    } else {
      // Handle other types of errors or unknown errors
      setMessage(verificationErrorMessage, t('errors.auth.generic'))
    }
  } finally {
    isLoading.value = false
  }
}
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100svw;
  height: 100svh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--color-auth-overlay);

  &::before {
    content: '';
    background-image: url('../../public/assets/images/main-background.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: var(--z-index-behind);
  }

  &::after {
    content: '';
    width: inherit;
    height: inherit;
    position: absolute;
    background-color: var(--color-auth-overlay);
  }

  @media (min-width: $device-xs) {
    padding: var(--size-40);
  }

  @media (min-width: $device-sm) {
    padding: var(--size-60);
  }

  @media (min-width: $device-md) {
    padding: var(--size-80);
  }

  @media (min-width: $device-lg) {
    padding: var (--size-100);
  }

  @media (min-width: $device-xl) {
    padding: var (--size-120);
  }
}

.auth-form {
  display: grid;
  grid-template-columns: 1fr; // Stacked layout on small screens
  gap: var(--size-60);
  padding: var(--size-40);
  overflow-y: scroll;
  z-index: var(--z-index-low);

  @media (min-width: $device-xs) {
    padding: var(--size-60);
  }

  @media (min-width: $device-sm) {
    padding: var(--size-80);
  }

  @media (min-width: $device-md) {
    padding: var(--size-100);
  }

  @media (min-width: $device-lg) {
    padding: var(--size-120);
  }

  // For larger screens, switch to side-by-side layout
  @media (min-width: $device-xl) {
    grid-template-columns: repeat(2, 1fr); // Two columns for larger screens
  }
}

.auth-form__section {
  padding: var(--size-80);
  border-radius: var(--size-60);
  width: 100%; // Take full width on small screens
  max-width: var(--size-auth-section-width); // Limit width on larger screens

  // Ensure right section appears first on smaller screens
  &--right {
    background-color: var(--color-body-bg);
    box-shadow: var(--color-shadow) 0 var(--size-20) var (--size-40) 0;
    order: -1; // Move the right section to the top on smaller screens
  }

  @media (min-width: $device-xl) {
    order: 0; // Restore the order for larger screens (side by side)
  }
}

.logo--mobile {
  display: block;

  @media (min-width: $device-xl) {
    display: none; // Hide on larger screens
  }
}

.logo--desktop {
  display: none;

  @media (min-width: $device-xl) {
    display: block; // Show on larger screens
    margin-left: -16px;
  }
}

.auth-button {
  padding: var(--size-40) var(--size-80);
  border: 0;
  outline: 0;
  margin-top: var(--size-40);
  border-radius: var(--size-160);
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background-color: var(--color-body-text);

  &:hover {
    background-color: var(--color-accent-50);
  }

  &--secondary {
    background-color: var(--color-accent-50-32);
    color: var(--color-body-text);

    &:hover {
      background-color: var(--color-accent-50);
      color: var(--color-neutral-90);
    }
  }
}

.auth-error {
  color: var(--color-warning-50);
}

.auth-success {
  color: var(--color-accent-50);
}

.auth-footer {
  display: flex;
  gap: var(--size-40);

  .icon {
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      color: var(--color-accent-50);
    }
  }
}
</style>
