<template>
  <div class="auth">
    <div class="auth-form">
      <section class="auth-form__section auth-form__section--left">
        <!-- Hide this logo on mobile -->
        <Logo class="logo--desktop" />
        <br />
        <br />
        <Text>{{ t('authPrices.fullExperienceFor') }}</Text>
        <Text is="h1" inline :style="{ color: 'var(--color-accent-50)' }">
          €0
        </Text>
        <Text
          inline
          is="h3"
          :style="{
            marginLeft: 'var(--size-10)',
            color: 'var(--color-accent-50)'
          }"
        >
          {{ t('authPrices.today') }}
        </Text>
        <br />
        <Text v-if="yearlyPrice && monthlyPrice" is="p3">
          {{
            t('authPrices.priceDetails', {
              yearlyPricePerMonth,
              yearlyPrice,
              monthlyPrice
            })
          }}
        </Text>
        <br />
        <br />

        <Text>
          <Icon class="card__icon" icon="check" />
          {{ t('authPrices.accessCollection') }}
        </Text>
        <br />
        <Text>
          <Icon class="card__icon" icon="check" />
          {{ t('authPrices.noAds') }}
        </Text>
        <br />
        <Text>
          <Icon class="card__icon" icon="check" />
          {{ t('authPrices.accessTools') }}
        </Text>
        <br />
        <Text>
          <Icon class="card__icon" icon="check" />
          {{ t('authPrices.newContentDaily') }}
        </Text>
        <br />
        <Text>
          <Icon class="card__icon" icon="check" />
          {{ t('authPrices.cancelAnytime') }}
        </Text>
      </section>

      <section class="auth-form__section auth-form__section--right">
        <!-- Show this logo on mobile -->
        <Logo class="logo--mobile" />
        <br />
        <Text is="h2">{{ t('authPrices.selectPlan') }}</Text>
        <br />
        <Prices />
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Prices from '@/components/Prices.vue'
import { computed } from 'vue'
import { useMainStore } from '@/state/index'
import { formatPrice } from '@/utils/functions'
import { useI18n } from 'vue-i18n'

defineOptions({
  name: 'AuthPricesComponent'
})

const { t } = useI18n()
const store = useMainStore()

// Compute the yearly and monthly prices from the available prices in the store
const yearlyPricePerMonth = computed(() => {
  const yearlyPrice =
    store.availablePrices
      .flatMap((product) => product.prices)
      .find((price) => price.interval === 'year')?.unit_amount || null

  return yearlyPrice ? formatPrice(yearlyPrice / 12, 'EUR') : null
})

const yearlyPrice = computed(() => {
  const yearlyPrice =
    store.availablePrices
      .flatMap((product) => product.prices)
      .find((price) => price.interval === 'year')?.unit_amount || null

  return yearlyPrice ? formatPrice(yearlyPrice, 'EUR') : null
})

const monthlyPrice = computed(() => {
  const monthlyPrice =
    store.availablePrices
      .flatMap((product) => product.prices)
      .find((price) => price.interval === 'month')?.unit_amount || null

  return monthlyPrice ? formatPrice(monthlyPrice, 'EUR') : null
})
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100svw;
  height: 100svh;
  position: fixed;
  background-color: var(--color-auth-overlay);

  &::before {
    content: '';
    background-image: url('../../public/assets/images/main-background.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: var(--z-index-behind);
  }

  &::after {
    content: '';
    width: inherit;
    height: inherit;
    position: absolute;
    background-color: var(--color-auth-overlay);
  }
}

.auth-form {
  display: grid;
  grid-template-columns: 1fr; // Stacked layout on small screens
  gap: var(--size-60);
  padding: var(--size-40);
  overflow-y: scroll;
  z-index: var(--z-index-low);

  @media (min-width: $device-xs) {
    padding: var(--size-60);
  }

  @media (min-width: $device-sm) {
    padding: var(--size-80);
  }

  @media (min-width: $device-md) {
    padding: var(--size-100);
  }

  @media (min-width: $device-lg) {
    padding: var(--size-120);
  }

  // For larger screens, switch to side-by-side layout
  @media (min-width: $device-xl) {
    grid-template-columns: repeat(2, 1fr); // Two columns for larger screens
  }
}

.auth-form__section {
  padding: var(--size-80);
  border-radius: var(--size-60);
  width: 100%; // Take full width on small screens
  max-width: var(--size-auth-section-width); // Limit width on larger screens

  // Ensure right section appears first on smaller screens
  &--right {
    background-color: var(--color-body-bg);
    box-shadow: var(--color-shadow) 0 var(--size-20) var(--size-40) 0;
    order: -1; // Move the right section to the top on smaller screens
    text-align: center;
  }

  @media (min-width: $device-xl) {
    order: 0; // Restore the order for larger screens (side by side)
  }
}

.logo--mobile {
  display: block;

  @media (min-width: $device-xl) {
    display: none; // Hide on larger screens
  }
}

.logo--desktop {
  display: none;

  @media (min-width: $device-xl) {
    display: block; // Show on larger screens
    margin-left: -16px;
  }
}
</style>
