<template>
  <div class="upsell-banner" @click="showPremiumModal">
    <Text>
      <Icon icon="clock" />
      {{ t('upsellBanner.message') }}
    </Text>
    <Text><Icon icon="arrow-right" /></Text>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useMainStore } from '@/state/index'
import Prices from '@/components/Prices.vue'

defineOptions({
  name: 'UpsellBannerComponent'
})

const { t } = useI18n()
const store = useMainStore()

function showPremiumModal() {
  store.setGlobalModal({
    visible: true,
    title: t('modal.upsellBanner.title'),
    subtitle: t('modal.upsellBanner.subtitle'),
    body: t('modal.upsellBanner.body'),
    component: Prices
  })
}
</script>

<style scoped lang="scss">
.upsell-banner {
  display: flex;
  justify-content: space-between;
  margin: 0 auto var(--size-80);
  border-radius: var(--size-30);
  padding: var(--size-40);
  gap: var(--size-30);
  min-height: var(--size-180);
  background-color: var(--color-accent-50-32);
  border: var(--size-1) solid var(--color-accent-50);
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: var(--color-link-hover);
  }
}
</style>
