import { Music } from './music'
import { Nature } from './nature'
import { Sessions } from './sessions'
import { Meditations } from './meditations'
import { Urban } from './urban'

// Types
type TypeSpaceLangData = {
  soundId: string
  title: string
  description?: string
  details?: string
  labels?: string[]
}

type TypeSpace = {
  type: 'music' | 'nature' | 'urban' | 'session' | 'meditation'
  imageId: string
  requiresPlan: boolean
  favorite: boolean
  new: boolean
  en: TypeSpaceLangData
  es: TypeSpaceLangData
  icons?: string[]
}

export type TypeSpaces = {
  [id: string]: TypeSpace
}

export const Spaces: TypeSpaces = {
  ...Nature,
  ...Urban,
  ...Music,
  ...Sessions,
  ...Meditations
}
