const companyName = 'Spaces.fm'
const companySloganEn = 'relax the monkey mind'

export const en = {
  company: {
    name: companyName,
    slogan: companySloganEn
  },
  copyright: `Copyright © 2024 ${companyName}. All rights reserved.`,
  loader: {
    title: `Loading ${companyName}...`,
    message: `Sit back and ${companySloganEn}.`
  },
  modal: {
    premium: {
      subtitle: 'This sound is part of our premium plan.',
      body: 'Discover transformative sounds, guided sessions, and meditations crafted to enhance focus, ease stress, and help you unwind—whenever you need it, with Spaces.fm premium.'
    },
    upsellBanner: {
      title: 'Experience the Full Power of Spaces.fm',
      subtitle:
        'Unlock all our sounds, meditations, and sessions for focus and relaxation.',
      body: 'Gain access to exclusive sounds, meditations, and guided sessions crafted to enhance focus, relaxation, and personal growth. Join our community and unlock your potential.'
    }
  },
  toast: {
    player: {
      loopEnabled: {
        title: 'Repeat Enabled',
        message: 'Repeat is now enabled for continuous playback.'
      },
      loopDisabled: {
        title: 'Repeat Disabled',
        message: 'Repeat is now disabled. Tracks will play sequentially.'
      },
      soundNotFound: {
        title: 'Sound not found',
        message:
          "Sorry! The title you're looking for isn't available right now. Try refreshing your browser or selecting another title."
      }
    },
    favorites: {
      removedFromFavorites: 'Removed from Favorites',
      addedToFavorites: 'Added to Favorites',
      removedFromFavoritesMessage: 'has been removed from your favorites.',
      addedToFavoritesMessage: 'has been added to your favorites.'
    },
    account: {
      reauthErrorTitle: 'Re-authentication Error',
      nameUpdatedTitle: 'Name updated!',
      nameUpdatedMessage: 'Your name has been updated to {name}.',
      nameUpdateErrorTitle: 'Error updating your name',
      verificationSentTitle: 'Verification email sent',
      verificationSentMessage:
        'A verification email has been sent to {email}. Please check your inbox.',
      verificationErrorTitle: 'Error sending verification',
      passwordUpdatedTitle: 'Password updated',
      passwordUpdatedMessage: 'Your password has been updated successfully.',
      passwordUpdateErrorTitle: 'Error updating password'
    }
  },
  upsellBanner: {
    message: `You're missing out on the full ${companyName} experience. Subscribe today to unlock premium content designed to elevate your well-being.`
  },
  main: {
    oops: 'Oops!',
    yourCollection: 'Your',
    ourCollection: 'Our',
    favoritesCollectionEmpty: 'favorites collection is empty.',
    natureCollectionEmpty: 'nature collection is empty.',
    urbanCollectionEmpty: 'urban collection is empty.',
    musicCollectionEmpty: 'music collection is empty.',
    sessionCollectionEmpty: 'session collection is empty.',
    shuffleCollectionEmpty: 'shuffle collection is empty.',
    soundCollectionEmpty: 'sound collection is empty.',
    startExploring: 'Start exploring',
    addFavorites: ' and add some sounds to your favorites!',
    tryReloading: 'Please try reloading the page or',
    exploreOtherCollections: 'explore other collections.'
  },
  account: {
    header: 'Your account',
    details: 'Your details.',
    changeName: 'Change your name:',
    newNamePlaceholder: 'Enter your new name',
    updateNameButton: 'Update my name',
    currentEmail: 'Your current email:',
    newEmail: 'Enter your new email:',
    newEmailPlaceholder: 'Enter new email',
    sendVerificationButton: 'Send verification to new email',
    verificationSentMessage:
      'Verification email sent to {email}. Please check your inbox.',
    changePassword: 'Change your password:',
    newPasswordPlaceholder: 'Enter your new password',
    updatePasswordButton: 'Update Password',
    subscription: 'Your subscription.',
    userUid: 'Your User ID.',
    appVersion: 'App Version',
    reauthTitle: 'First re-authenticate!',
    reauthSubtitle: 'Please re-authenticate to proceed.',
    reauthButton: 'Re-authenticate'
  },
  doNothing: {
    doNothingFor: 'Do nothing for',
    minutes: 'minutes.',
    wellDone: 'Well done!',
    tryAgain: 'Try again!',
    restart: 'Restart',
    start: 'Start',
    instructionsInProgress: "Don't touch your mouse, trackpad, or keyboard.",
    instructionsStart: "Press any key or the 'Start' button to start.",
    totalTime: "Your current total 'Do Nothing' time: {time}."
  },
  justBreathe: {
    toast: {
      title: 'Customize your breathing intervals.',
      message:
        'Adjust your inhale, exhale, and hold times for a tailored breathing experience.'
    },
    controls: {
      restart: 'Restart',
      inhale: 'Inhale',
      holdIn: 'Hold in',
      exhale: 'Exhale',
      holdOut: 'Hold out'
    },
    instructions: {
      inhale: 'Inhale',
      holdIn: 'Hold',
      exhale: 'Exhale',
      holdOut: 'Hold'
    },
    start: 'Start',
    stop: 'Stop',
    followInstruction:
      'Focus on your breathing. Inhale, hold, and exhale following the circles.',
    startInstruction: "Press any key or the 'Start' button to start.",
    totalTime: 'Your current total breathing time: {time}.'
  },
  wiseWords: {
    slideDelayLabel: 'Slide delay in seconds',
    secondsLeft: {
      smallDevice: 'Seconds.',
      largeDevice: 'Seconds left.'
    },
    footerButtons: {
      prev: 'Previous slide',
      next: 'Next slide',
      play: 'Play slides',
      pause: 'Pause slides'
    },
    quote: 'Quote',
    author: 'Author'
  },
  prices: {
    loader: {
      message: 'Relax while we connect you with our payments provider.'
    },
    perMonth: '/month',
    bestValue: 'Best value',
    interval: {
      yearly: 'Yearly',
      monthly: 'Monthly'
    },
    daysFree: '7 days free',
    billedAnnually: 'Billed annually at {amount}.',
    enterVoucher: 'Enter your voucher code',
    tryForFree: `Try {companyName} for Free`,
    membershipSelection: 'Please select a membership to proceed.',
    freeTrialMessage:
      "After your free {days}-day trial, it's {price} per {interval}, auto-renewing. Cancel anytime.",
    activeSubscription: 'You have an active subscription!',
    updateSubscription: 'Update my subscription',
    skipSubscription: 'Continue without subscription'
  },
  user: {
    greeting: {
      morning: 'Good morning',
      afternoon: 'Good afternoon',
      evening: 'Good evening'
    }
  },
  sidebar: {
    sections: [
      {
        title: 'Collections',
        tabs: {
          shuffle: 'Shuffle',
          nature: 'Nature',
          urban: 'Urban',
          music: 'Music',
          sessions: 'Sessions',
          meditations: 'Meditations',
          favorites: 'Favorites'
        }
      },
      {
        title: 'Apps',
        tabs: {
          doNothing: 'Do nothing',
          justBreathe: 'Just breathe',
          wiseWords: 'Wise words'
        }
      },
      {
        title: 'Settings',
        tabs: {
          account: 'My account',
          stats: 'My stats',
          awards: 'My awards',
          mode: {
            light: 'Dark mode',
            dark: 'Light mode'
          }
        }
      },
      {
        tabs: {
          collapse: 'Collapse sidebar'
        }
      }
    ]
  },
  player: {
    loading: 'Loading',
    favorites: {
      add: 'Add to my favorites',
      remove: 'Remove from my favorites'
    }
  },
  card: {
    new: 'New'
  },
  time: {
    seconds: 'second | seconds',
    minutes: 'minute | minutes',
    hours: 'hour | hours',
    days: 'day | days'
  },
  auth: {
    header: {
      newTo: `New to ${companyName}?`,
      tryForFree: `Try ${companyName} for Free`,
      alreadyHaveAccount: 'Already have an account?',
      logIn: 'Log into your account.'
    },
    signup: {
      alreadyAccount: 'Already have an account?'
    },
    login: {
      welcomeBack: 'Welcome back!',
      readyToDive:
        'Great to see you again. Ready to dive back into your favorite sounds?'
    },
    social: {
      or: 'Or'
    }
  },
  authLogin: {
    heading: 'Log into your account.',
    emailPlaceholder: 'Your email *',
    passwordPlaceholder: 'Your password *',
    forgotPassword: 'Forgot your password?',
    continue: 'Continue'
  },
  authSignUp: {
    heading: 'Create a new account.',
    namePlaceholder: 'Your name *',
    emailPlaceholder: 'Your email *',
    passwordPlaceholder: 'Your password (8+ characters) *',
    continue: 'Continue',
    errors: {
      passwordTooShort: 'Password must be at least 8 characters long.'
    }
  },
  authEmailVerify: {
    almostThere: 'Almost there!',
    message: `Please verify your email to ensure a real and safe ${companyName} community. It only takes a minute.`,
    verifyEmail: 'Please verify your email to continue.',
    checkInbox: 'Check your inbox for the verification link.',
    checkStatus: 'Check verification status',
    didntReceiveEmail: "Didn't receive the email?",
    resendEmail: 'Resend verification email',
    emailVerified: 'Email verified!',
    emailNotVerified: 'Your email is not verified yet.',
    resendEmailSuccess:
      'Verification email has been sent! Please check your inbox.'
  },
  authLogOut: {
    buttonText: 'Log out',
    successMessage: 'Successfully logged out.',
    errorMessage: 'Error logging out.'
  },
  authForgotPassword: {
    heading: 'Forgot your password?',
    emailPlaceholder: 'Enter your email *',
    buttonText: 'Send reset link',
    resetEmailSent:
      'Email sent! Please check your inbox and click the link to reset your password.',
    enterEmail:
      'Enter your email, and we’ll send you a link to reset your password.',
    redirectMessage: 'Hang tight! Redirecting you to the login screen.'
  },
  authResetPassword: {
    heading: 'Reset Your Password',
    newPasswordPlaceholder: 'Enter your new password *',
    confirmPasswordPlaceholder: 'Confirm your new password *',
    buttonText: 'Reset Password',
    invalidCode:
      'The link is either invalid or expired. Please try again through the forgot password process.',
    yourEmail: 'Your email:',
    passwordMismatch: 'The passwords must match.',
    passwordTooShort: 'Your password must be at least 8 characters long.',
    resetSuccess: 'Success! Your password has been reset.',
    redirectMessage: 'Hold on! Redirecting you to the login screen.'
  },
  authPrices: {
    fullExperienceFor: `Get the full ${companyName} content experience for:`,
    today: 'today.',
    priceDetails:
      '{yearlyPricePerMonth} per month, billed annually at {yearlyPrice}/year after your free 7-day trial, or {monthlyPrice} per month, billed monthly at {monthlyPrice}/month after your free 7-day trial.',
    accessCollection:
      'Access our growing collection of sounds, music, and guided sessions.',
    noAds: 'No ads for uninterrupted focus and relaxation.',
    accessTools:
      'Access our tools like Do Nothing and Wise Words, with more features coming soon.',
    newContentDaily: 'New content daily.',
    cancelAnytime: 'Cancel anytime.',
    selectPlan: 'Select your plan.'
  },
  authSocials: {
    google: 'Continue with Google',
    facebook: 'Continue with Facebook',
    error: 'An error occurred during social login.'
  },
  stats: {
    title: 'Your Stats',
    message:
      'Track your progress with these charts. They display total minutes spent daily, weekly, monthly, and yearly. Blue represents "Just breathe" and orange represents "Do nothing." Stay consistent and watch your relaxation time grow!',
    noData: 'No data available yet.',
    feature: {
      doNothing: 'Do nothing',
      justBreathe: 'Just breathe',
      wiseWords: 'Wise words'
    },
    total: 'Total',
    today: 'Today',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly'
  },
  awards: {
    title: 'Your awards',
    feature: {
      doNothing: 'Do Nothing',
      justBreathe: 'Just Breathe',
      wiseWords: 'Wise Words'
    },
    levels: {
      initiate: 'Initiate',
      novice: 'Novice',
      beginner: 'Beginner',
      adept: 'Adept',
      skilled: 'Skilled',
      expert: 'Expert',
      guru: 'Guru'
    }
  },
  errors: {
    noPricesTitle: 'No available prices',
    noPricesMessage: 'Unable to load prices. Please try again later.',
    loginRequiredTitle: 'Login required',
    loginRequiredMessage: 'Please log in to continue.',
    membershipSelectionTitle: 'Membership selection',
    voucherErrorTitle: 'Voucher error',
    unexpectedErrorTitle: 'Unexpected error',
    unexpectedErrorMessage:
      'An unexpected error occurred. Please try again later.',
    portalErrorTitle: 'Customer portal error',
    portalErrorMessage:
      'Unable to open the customer portal. Please try again later.',
    auth: {
      claimsTooLarge: 'The claims data is too large.',
      emailAlreadyExists: 'This email is already in use.',
      idTokenExpired: 'Your session has expired. Please log in again.',
      idTokenRevoked: 'Your session has been revoked. Please log in again.',
      insufficientPermission: 'You do not have the necessary permissions.',
      internalError: 'An internal error occurred. Please try again later.',
      invalidArgument: 'An invalid argument was provided.',
      invalidClaims: 'Invalid custom claims were provided.',
      invalidContinueUri: 'The provided continue URL is invalid.',
      invalidCreationTime: 'The creation time must be a valid UTC date.',
      invalidCredential: 'Invalid email or password. Please try again.',
      invalidDisabledField: 'The disabled field value is invalid.',
      invalidDisplayName: 'The display name must be a non-empty string.',
      invalidDynamicLinkDomain: 'The dynamic link domain is unauthorized.',
      invalidEmail: 'The provided email is invalid.',
      invalidEmailVerified: 'The email verification status is invalid.',
      invalidHashAlgorithm: 'The hash algorithm is unsupported.',
      invalidHashBlockSize: 'The hash block size is invalid.',
      invalidHashDerivedKeyLength: 'The hash derived key length is invalid.',
      invalidHashKey: 'The hash key is invalid.',
      invalidHashMemoryCost: 'The memory cost for the hash is invalid.',
      invalidHashParallelization:
        'The parallelization for the hash is invalid.',
      invalidHashRounds: 'The number of hash rounds is invalid.',
      invalidHashSaltSeparator: 'The salt separator for the hash is invalid.',
      invalidIdToken: 'The provided ID token is invalid.',
      invalidLastSignInTime: 'The last sign-in time is invalid.',
      invalidPageToken: 'The next page token is invalid.',
      invalidPassword: 'The provided password is invalid.',
      invalidPasswordHash: 'The password hash is invalid.',
      invalidPasswordSalt: 'The password salt is invalid.',
      invalidPhoneNumber: 'The provided phone number is invalid.',
      invalidPhotoUrl: 'The provided photo URL is invalid.',
      invalidProviderData: 'The provider data is invalid.',
      invalidProviderId: 'The provider ID is invalid.',
      invalidOauthResponseType: 'Only one OAuth response type is allowed.',
      invalidSessionCookieDuration: 'The session cookie duration is invalid.',
      invalidUid: 'The user ID is invalid.',
      invalidUserImport: 'The user record for import is invalid.',
      maximumUserCountExceeded: 'Maximum user count exceeded.',
      missingAndroidPkgName: 'Android package name is required.',
      missingContinueUri: 'A valid continue URL is required.',
      missingHashAlgorithm: 'The hash algorithm is required for import.',
      missingIosBundleId: 'The iOS bundle ID is missing.',
      missingUid: 'A user ID is required for this operation.',
      missingOauthClientSecret: 'The OAuth client secret is missing.',
      operationNotAllowed: 'This operation is not allowed.',
      phoneNumberAlreadyExists: 'This phone number is already in use.',
      projectNotFound: 'No Firebase project was found.',
      reservedClaims: 'One or more custom claims are reserved.',
      sessionCookieExpired: 'The session cookie has expired.',
      sessionCookieRevoked: 'The session cookie has been revoked.',
      tooManyRequests: 'Too many requests. Please try again later.',
      uidAlreadyExists: 'This user ID is already in use.',
      unauthorizedContinueUri: 'The continue URL is not authorized.',
      userNotFound: 'No user found for this identifier.',
      cancelledPopupRequest: 'You canceled the Google login. Please try again.',
      generic: 'An error occurred. Please try again.'
    }
  }
}
