<template>
  <component :is="tag" :class="['text', is, { inline }, { centered }]">
    <slot />
  </component>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue'

defineOptions({
  name: 'TextComponent'
})

const tag = ref('p')

const props = withDefaults(
  defineProps<{
    is?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p1' | 'p2' | 'p3'
    flex?: boolean
    inline?: boolean
    centered?: boolean
  }>(),
  {
    is: 'p2'
  }
)

watchEffect(() => {
  const tagValues = {
    p1: 'p',
    p2: 'p',
    p3: 'small',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6'
  }

  // Check if the props.is value exists in the map using the 'in' operator
  if (props.is in tagValues) {
    return (tag.value = tagValues[props.is])
  }
})
</script>

<style scoped lang="scss">
h1,
h2,
h3,
h4,
h5,
h6,
p,
small {
  display: flex;
  align-items: center;
  gap: var(--size-30);
}

.inline {
  display: inline-flex;
  align-items: center;
  gap: var(--size-30);
}

.h1 {
  font-size: var(--font-size-h1);
}

.h2 {
  font-size: var(--font-size-h2);
}

.h3 {
  font-size: var(--font-size-h3);
}

.h4 {
  font-size: var(--font-size-h4);
}

.h5 {
  font-size: var(--font-size-h5);
}

.h6 {
  font-size: var(--font-size-h6);
}

.p1 {
  font-size: var(--font-size-p1);
}

.p2 {
  font-size: var(--font-size-p2);
}

.p3 {
  font-size: var(--font-size-p3);
  line-height: 1.7;
}

.p4 {
  font-size: var(--font-size-p4);
  line-height: 1.3;
}

.centered {
  justify-content: center;
  text-align: center;
}
</style>
