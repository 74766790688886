import { db } from '@/services/firebase'
import {
  collection,
  query,
  where,
  addDoc,
  getDocs,
  onSnapshot,
  type DocumentData
} from 'firebase/firestore'
import type { Product } from '@/types'

interface CheckoutSessionData extends DocumentData {
  error?: { message: string }
  url?: string
}

/**
 * Fetches active products and their active prices.
 * @return {Promise<Product[]>} A promise that resolves to an array of products with their active prices.
 */
export async function fetchProductsWithPrices(): Promise<Product[]> {
  const productsRef = collection(db, 'products')
  const q = query(productsRef, where('active', '==', true))
  const productsWithPrices: Product[] = []

  try {
    const querySnapshot = await getDocs(q)

    if (querySnapshot.empty) {
      // console.log('No products found')
      return productsWithPrices
    }

    for (const doc of querySnapshot.docs) {
      const productData: Product = {
        id: doc.id,
        name: doc.data().name,
        description: doc.data().description,
        prices: []
      }

      const pricesRef = collection(doc.ref, 'prices')
      const priceSnap = await getDocs(pricesRef)

      if (priceSnap.empty) {
        console.log(`No prices found for product ${doc.id}`)
      } else {
        priceSnap.forEach((priceDoc) => {
          const priceData = priceDoc.data()
          if (priceData.active) {
            productData.prices.push({
              priceId: priceDoc.id,
              unit_amount: priceData.unit_amount,
              currency: priceData.currency,
              interval: priceData.interval
            })
          }
        })
      }

      productsWithPrices.push(productData)
    }

    return productsWithPrices
  } catch (error) {
    console.error('Error fetching products:', error)
    throw error
  }
}

export async function fetchUserSubscriptions(
  userUid: string
): Promise<boolean> {
  if (!userUid) return false

  const userSubsRef = collection(db, 'users', userUid, 'subscriptions')
  const q = query(userSubsRef, where('status', 'in', ['active', 'trialing']))

  try {
    const querySnapshot = await getDocs(q)
    return !querySnapshot.empty
  } catch (error) {
    console.error('Error fetching subscriptions: ', error)
    return false
  }
}

export async function createCheckoutSession(
  userUid: string,
  priceId: string,
  trialPeriodDays: number = 0,
  voucher: string = ''
): Promise<{ success: boolean; errorMessage?: string }> {
  // console.log(
  //   'Creating session for user:',
  //   userUid,
  //   'price:',
  //   priceId,
  //   'voucher:',
  //   voucher
  // )

  if (!userUid) {
    return { success: false, errorMessage: 'No user logged in' }
  }

  try {
    const checkoutSessionsRef = collection(
      db,
      'users',
      userUid,
      'checkout_sessions'
    )

    const sessionData: DocumentData = {
      price: priceId,
      allow_promotion_codes: true,
      trial_period_days: trialPeriodDays,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      billing_address_collection: 'auto'
    }

    if (voucher) {
      sessionData.promotion_code = voucher
    }

    const docRef = await addDoc(checkoutSessionsRef, sessionData)

    // Use a Promise to handle the onSnapshot response
    return new Promise((resolve) => {
      onSnapshot(docRef, (snap) => {
        const data = snap.data() as CheckoutSessionData

        if (data) {
          const { error, url } = data

          if (error) {
            console.error('Error in session:', error.message)
            resolve({ success: false, errorMessage: error.message })
          } else if (url) {
            window.location.assign(url)
          }
        }
      })
    })
  } catch (error) {
    console.error('Failed to create checkout session:', error)
    const err = error as Error
    return {
      success: false,
      errorMessage: err.message || 'An unknown error occurred'
    }
  }
}
