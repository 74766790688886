import { ref, defineComponent, computed, markRaw } from 'vue'
import { defineStore } from 'pinia'
import { Spaces } from '@/data/spaces'
import { useUser } from '@/hooks/useUser'
import { fetchProductsWithPrices } from '@/hooks/useDB'
import { useStats } from '@/hooks/useStats'
import { lang } from '@/services/i18n'
import { type Product } from '@/types'

export const useMainStore = defineStore('main', () => {
  const defaultSoundId = ref<string>('rain-undercover')
  const playingSound = ref<(typeof Spaces)['rain-undercover'] | null>(null)
  const isPlaying = ref<boolean>(false)
  const userFavorites = ref<string[]>([])
  const filteredSpaces = ref<{
    [key: string]: (typeof Spaces)[keyof typeof Spaces]
  }>({})
  const shuffledSpaces = ref<typeof Spaces>({})
  const availablePrices = ref<Product[]>([])
  const currentTrackIndex = ref<number>(0)

  const filteredOrder = computed(() =>
    Object.values(filteredSpaces.value).map((space) => space[lang].soundId)
  )

  const userHasSubscription = ref(false)
  const userSubscriptionDetails = ref<{ priceId: string } | null>(null)
  const hasSkippedSubscription = ref<boolean>(
    localStorage.getItem('has-skipped-subscription') === 'true'
  )

  const globalModal = ref({
    visible: false,
    title: '',
    subtitle: '',
    body: '',
    component: '',
    footer: '',
    buttonText: '',
    buttonAction: () => {}
  })

  const { updateUserFavorites, currentUser } = useUser()
  const {
    breathingStats,
    doNothingStats,
    updateBreathingTime,
    fetchBreathingStats,
    updateDoNothingTime,
    fetchDoNothingStats
  } = useStats()

  const toast = ref({
    visible: false,
    title: '',
    message: '',
    type: '' as 'success' | 'warning'
  })

  // Setters
  const setPlayingSound = (
    sound: (typeof Spaces)['rain-undercover'] | null
  ) => {
    playingSound.value = sound
    isPlaying.value = true
  }

  const incrementTrackIndex = () => {
    if (currentTrackIndex.value < filteredOrder.value.length - 1) {
      currentTrackIndex.value += 1
    }
  }

  const decrementTrackIndex = () => {
    if (currentTrackIndex.value > 0) {
      currentTrackIndex.value -= 1
    }
  }

  const setFavoriteStatus = (favorites: string[]) => {
    userFavorites.value = favorites
    updateFavoriteStatusForCurrentSound()
  }

  const setFilteredSpaces = (spaces: typeof Spaces) => {
    const mappedSpaces = Object.fromEntries(
      Object.entries(spaces).map(([, space]) => [space[lang].soundId, space])
    )
    filteredSpaces.value = mappedSpaces
  }

  const setShuffledSpaces = (spaces: typeof Spaces) => {
    shuffledSpaces.value = spaces
  }

  const setGlobalModal = (
    modal: Partial<{
      visible: boolean
      title: string
      subtitle: string
      body: string
      component: ReturnType<typeof defineComponent>
      footer: string
      buttonText: string
      buttonAction: () => void
    }>
  ) => {
    globalModal.value = {
      ...globalModal.value,
      ...modal,
      component: modal.component ? markRaw(modal.component) : undefined
    }
  }

  const setUserHasSubscription = (hasSubscription: boolean) => {
    userHasSubscription.value = hasSubscription
  }

  const setUserSubscriptionDetails = (details: { priceId: string } | null) => {
    userSubscriptionDetails.value = details
  }

  const setSkippedSubscription = (value: boolean) => {
    hasSkippedSubscription.value = value
    localStorage.setItem('has-skipped-subscription', value.toString())
  }

  const setGlobalToast = (newToast: {
    visible: boolean
    title: string
    message: string
    type: 'success' | 'warning'
  }) => {
    toast.value = { ...newToast }
  }

  const closeGlobalToast = () => {
    toast.value.visible = false
  }

  const setBreathingTime = async (timeInSeconds: number) => {
    if (currentUser.value?.uid) {
      await updateBreathingTime(currentUser.value.uid, timeInSeconds)
    }
  }

  const setDoNothingTime = async (timeInSeconds: number) => {
    if (currentUser.value?.uid) {
      await updateDoNothingTime(currentUser.value.uid, timeInSeconds)
    }
  }

  const getBreathingStats = async () => {
    if (currentUser.value?.uid) {
      await fetchBreathingStats(currentUser.value.uid)
    }
  }

  const getDoNothingStats = async () => {
    if (currentUser.value?.uid) {
      await fetchDoNothingStats(currentUser.value.uid)
    }
  }

  const togglePlaying = (playing: boolean) => {
    isPlaying.value = playing
  }

  const toggleFavorite = async (soundId: string) => {
    if (!currentUser.value) return

    const isCurrentlyFavorite = userFavorites.value.includes(soundId)
    const newFavoriteStatus = !isCurrentlyFavorite

    try {
      await updateUserFavorites(soundId, newFavoriteStatus)

      if (newFavoriteStatus) {
        userFavorites.value.push(soundId)
      } else {
        userFavorites.value = userFavorites.value.filter((id) => id !== soundId)
      }

      updateFavoriteStatusForCurrentSound()
    } catch (error) {
      console.error('Error toggling favorite:', error)
    }
  }

  const setAvailablePrices = async () => {
    try {
      const products = await fetchProductsWithPrices()
      availablePrices.value = products
    } catch (error) {
      console.error('Failed to fetch prices:', error)
    }
  }

  function updateFavoriteStatusForCurrentSound() {
    if (playingSound.value) {
      playingSound.value.favorite = userFavorites.value.includes(
        playingSound.value[lang].soundId
      )
    }
  }

  return {
    defaultSoundId,
    playingSound,
    isPlaying,
    currentTrackIndex,
    filteredOrder,
    userFavorites,
    filteredSpaces,
    shuffledSpaces,
    userHasSubscription,
    userSubscriptionDetails,
    hasSkippedSubscription,
    globalModal,
    availablePrices,
    toast,
    breathingStats,
    doNothingStats,
    setPlayingSound,
    setFavoriteStatus,
    setFilteredSpaces,
    setShuffledSpaces,
    setGlobalModal,
    setUserHasSubscription,
    setUserSubscriptionDetails,
    setSkippedSubscription,
    setAvailablePrices,
    setGlobalToast,
    incrementTrackIndex,
    decrementTrackIndex,
    closeGlobalToast,
    setBreathingTime,
    setDoNothingTime,
    getBreathingStats,
    getDoNothingStats,
    toggleFavorite,
    togglePlaying
  }
})
