<template>
  <main class="app">
    <!-- Toast Component controlled by toast state in the store -->
    <Toast
      :visible="store.toast.visible"
      :title="store.toast.title"
      :message="store.toast.message"
      :type="store.toast.type"
      @close="store.closeGlobalToast"
    />

    <!-- Show loader if auth check hasn't completed -->
    <Loader v-if="!authCheckCompleted" />

    <!-- Render the app content conditionally based on currentUser and auth states -->
    <Auth
      v-else-if="shouldShowAuth"
      :yearlyPrice="yearlyPrice"
      :monthlyPrice="monthlyPrice"
      state="login"
    />

    <AuthEmailVerify
      v-else-if="shouldShowEmailVerification"
      @email-verified="handleEmailVerified"
    />

    <AuthPrices v-else-if="shouldShowAuthPrices" />

    <template v-else>
      <div class="app__sidebar">
        <Sidebar />
      </div>

      <router-view class="app__main" />

      <!-- Conditionally render the Player if the route is not 'do-nothing' -->
      <Player class="app__player" />

      <!-- Global Modal Component -->
      <Modal
        v-model:visible="globalModal.visible"
        :title="globalModal.title"
        :subtitle="globalModal.subtitle"
        :body="globalModal.body"
        :component="globalModal.component"
        :footer="globalModal.footer"
        :buttonText="globalModal.buttonText"
        :buttonAction="globalModal.buttonAction"
      />
    </template>
  </main>
</template>

<script lang="ts" setup>
import Auth from '@/views/Auth.vue'
import Loader from '@/components/Loader.vue'
import Sidebar from '@/components/Sidebar.vue'
import Player from '@/components/Player.vue'
import Modal from '@/components/Modal.vue'
import AuthEmailVerify from '@/components/AuthEmailVerify.vue'
import AuthPrices from '@/components/AuthPrices.vue'
import { fetchUserSubscriptions } from '@/hooks/useDB'
import { useMainStore } from '@/state/index'
import { useUser } from '@/hooks/useUser'
import { useRouter } from 'vue-router'
import { ref, watch, computed, onMounted, nextTick } from 'vue'
import { reload } from 'firebase/auth'
import { auth } from '@/services/firebase'

// Initialize Pinia store
const store = useMainStore()

// Fetch user details from useUser composable
const { userUid, currentUser } = useUser()

const authCheckCompleted = ref(false)
const userHasSubscription = computed(() => store.userHasSubscription)
const minimumLoadingTime = 1000 // Minimum 1 second loader display time
const router = useRouter()

// Modal visibility and content are reactive from the store
const globalModal = computed(() => store.globalModal)

let authTimeout: ReturnType<typeof setTimeout> | null = null

// Function to clear any pending timeouts
const clearTimeouts = () => {
  if (authTimeout) {
    clearTimeout(authTimeout)
    authTimeout = null
  }
}

// Function to scroll active cards into view
const activateCard = async () => {
  await nextTick()
  const activeCards = document.getElementsByClassName('card active')
  if (activeCards.length > 0) {
    const activeCard = activeCards[0]
    activeCard.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}

// Function to ensure minimum loading time
const showLoaderForMinimumTime = async (startTime: number) => {
  const elapsedTime = Date.now() - startTime
  const remainingTime = minimumLoadingTime - elapsedTime

  if (remainingTime > 0) {
    await new Promise((resolve) => setTimeout(resolve, remainingTime))
  }
}

// Handle email verification completion and fetch subscription
const handleEmailVerified = async () => {
  const user = auth.currentUser
  if (user) {
    // Reload user to ensure emailVerified is updated
    await reload(user)

    // After reloading, check if the email is verified
    if (user.emailVerified) {
      // Now you can fetch the subscription status
      if (userUid.value) {
        const hasSubscription = await fetchUserSubscriptions(userUid.value)
        store.setUserHasSubscription(hasSubscription)
        router.push('/subscription') // Redirect to /subscription
      }
    } else {
      console.log('Email is still not verified.')
    }
  }
}

// Prices for Auth component
const yearlyPrice = ref<number | null>(null)
const monthlyPrice = ref<number | null>(null)

onMounted(() => {
  // Fetch prices when the component is mounted
  store.setAvailablePrices()

  // @TODO: Remove if not needed, this overwrites the router on load and it's not desired.
  // Check if the 'sound' query param is missing in the URL
  // if (!router.currentRoute.value.query.sound) {
  //   // Redirect to / with the first sound in the query param
  //   router.replace({
  //     path: router.currentRoute.value.path,
  //     query: {
  //       ...router.currentRoute.value.query,
  //       sound: store.defaultSoundId
  //     }
  //   })
  // }
})

// Watch for auth status and determine if the auth check has been completed
watch(
  currentUser,
  async (newValue) => {
    const startTime = Date.now() // Track when the loader starts

    if (newValue?.emailVerified) {
      const hasSubscription = await fetchUserSubscriptions(newValue.uid)
      store.setUserHasSubscription(hasSubscription)
    }

    clearTimeouts() // Clear any pending timeouts

    // Ensure at least 1 second of loading time
    await showLoaderForMinimumTime(startTime)

    authCheckCompleted.value = true // Set auth check completed after minimum time
    activateCard()
  },
  { immediate: true }
)

// Watch for route changes and activate card scroll
watch(
  () => router.currentRoute.value.query,
  async () => {
    const startTime = Date.now() // Track when the loader starts

    clearTimeouts() // Clear any pending timeouts

    // Ensure at least 1 second of loading time
    await showLoaderForMinimumTime(startTime)

    activateCard()
  },
  { immediate: true }
)

// Computed properties for showing components
const shouldShowAuth = computed(() => !currentUser.value)
const shouldShowEmailVerification = computed(
  () => currentUser.value && !currentUser.value.emailVerified
)
const hasSkippedSubscription = computed(() => store.hasSkippedSubscription)
const shouldShowAuthPrices = computed(
  () =>
    currentUser.value?.emailVerified &&
    !userHasSubscription.value &&
    !hasSkippedSubscription.value
)
</script>

<style lang="scss">
.app {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: calc(100svh - var(--size-player-height));
  grid-template-areas:
    'sidebar main main'
    'sidebar main main'
    'sidebar player player';
}

.app__sidebar {
  display: none;
  @media (min-width: $device-lg) {
    display: initial;
    grid-area: sidebar;
  }
}

.app__main {
  grid-area: main;
  padding: 40px;
  height: calc(100svh - var(--size-player-height));
  overflow-y: scroll;

  // :-webkit-scrollbar {
  //   display: none;
  // }

  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
}

.app__player {
  grid-area: player;
}
</style>
