<template>
  <i :class="['icon', `icon--${icon}`]" :style="{ fontSize: iconSize }"></i>
</template>

<script lang="ts">
export default {
  name: 'IconComponent'
}
</script>

<script lang="ts" setup>
export type Icon =
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-up'
  | 'calendar-monthly'
  | 'calendar-today'
  | 'calendar-total'
  | 'calendar-weekly'
  | 'calendar-yearly'
  | 'caret-down'
  | 'caret-left'
  | 'caret-right'
  | 'caret-up'
  | 'check'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'clock'
  | 'collapse'
  | 'collection-favorite'
  | 'collection-meditation'
  | 'collection-music'
  | 'collection-nature'
  | 'collection-scenes'
  | 'collection-session'
  | 'collection-shuffle'
  | 'collection-sleep-stories'
  | 'collection-urban'
  | 'expand'
  | 'favorite'
  | 'favorite-outlined'
  | 'feature-do-nothing'
  | 'feature-just-breathe'
  | 'feature-wise-words'
  | 'focus'
  | 'headphones'
  | 'lock'
  | 'minus'
  | 'player-backward'
  | 'player-forward'
  | 'player-loop'
  | 'player-next'
  | 'player-pause'
  | 'player-play'
  | 'player-previous'
  | 'player-stop'
  | 'refresh'
  | 'settings'
  | 'settings-account'
  | 'settings-awards'
  | 'settings-light-dark'
  | 'settings-stats'
  | 'settings-subscription'
  | 'sliders'
  | 'socials-facebook'
  | 'socials-linkedin'
  | 'socials-twitter'
  | 'socials-youtube'
  | 'x'

const props = withDefaults(
  defineProps<{
    icon: Icon
    size?:
      | 'inherit'
      | 10
      | 20
      | 30
      | 40
      | 50
      | 60
      | 70
      | 80
      | 90
      | 100
      | 110
      | 120
      | 130
      | 140
  }>(),
  {
    icon: 'player-play',
    size: 'inherit'
  }
)

const iconSize = getComputedStyle(document.documentElement).getPropertyValue(
  `--font-size-${props.size}`
)
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'spaces-icons';
  src:
    url('@/assets/fonts/spaces-icons.ttf') format('truetype'),
    url('@/assets/fonts/spaces-icons.woff') format('woff'),
    url('@/assets/fonts/spaces-icons.svg#spaces-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon--'],
[class*=' icon--'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'spaces-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--arrow-down:before {
  content: '\e900';
}
.icon--arrow-left:before {
  content: '\e901';
}
.icon--arrow-right:before {
  content: '\e902';
}
.icon--arrow-up:before {
  content: '\e903';
}
.icon--calendar-monthly:before {
  content: '\e933';
}
.icon--calendar-today:before {
  content: '\e934';
}
.icon--calendar-total:before {
  content: '\e935';
}
.icon--calendar-weekly:before {
  content: '\e936';
}
.icon--calendar-yearly:before {
  content: '\e937';
}
.icon--caret-down:before {
  content: '\e925';
}
.icon--caret-left:before {
  content: '\e926';
}
.icon--caret-right:before {
  content: '\e927';
}
.icon--caret-up:before {
  content: '\e928';
}
.icon--check:before {
  content: '\e904';
}
.icon--chevron-down:before {
  content: '\e905';
}
.icon--chevron-left:before {
  content: '\e906';
}
.icon--chevron-right:before {
  content: '\e907';
}
.icon--chevron-up:before {
  content: '\e908';
}
.icon--clock:before {
  content: '\e929';
}
.icon--collapse:before {
  content: '\e92a';
}
.icon--collection-favorite:before {
  content: '\e909';
}
.icon--collection-meditation:before {
  content: '\e90a';
}
.icon--collection-music:before {
  content: '\e90b';
}
.icon--collection-nature:before {
  content: '\e90c';
}
.icon--collection-scenes:before {
  content: '\e92b';
}
.icon--collection-session:before {
  content: '\e90d';
}
.icon--collection-shuffle:before {
  content: '\e90e';
}
.icon--collection-sleep-stories:before {
  content: '\e92c';
}
.icon--collection-urban:before {
  content: '\e90f';
}
.icon--expand:before {
  content: '\e92d';
}
.icon--favorite-outlined:before {
  content: '\e910';
}
.icon--favorite:before {
  content: '\e911';
}
.icon--feature-do-nothing:before {
  content: '\e912';
}
.icon--feature-just-breathe:before {
  content: '\e924';
}
.icon--feature-wise-words:before {
  content: '\e913';
}
.icon--focus:before {
  content: '\e92e';
}
.icon--headphones:before {
  content: '\e92f';
}
.icon--lock:before {
  content: '\e914';
}
.icon--minus:before {
  content: '\e915';
}
.icon--player-backward:before {
  content: '\e916';
}
.icon--player-forward:before {
  content: '\e917';
}
.icon--player-loop:before {
  content: '\e93b';
}
.icon--player-next:before {
  content: '\e938';
}
.icon--player-pause:before {
  content: '\e918';
}
.icon--player-play:before {
  content: '\e919';
}
.icon--player-previous:before {
  content: '\e93a';
}
.icon--player-stop:before {
  content: '\e91a';
}
.icon--refresh:before {
  content: '\e91b';
}
.icon--settings-account:before {
  content: '\e91c';
}
.icon--settings-awards:before {
  content: '\e939';
}
.icon--settings-light-dark:before {
  content: '\e91d';
}
.icon--settings-stats:before {
  content: '\e930';
}
.icon--settings-subscription:before {
  content: '\e91e';
}
.icon--settings:before {
  content: '\e931';
}
.icon--sliders:before {
  content: '\e932';
}
.icon--socials-facebook:before {
  content: '\e91f';
}
.icon--socials-linkedin:before {
  content: '\e920';
}
.icon--socials-twitter:before {
  content: '\e921';
}
.icon--socials-youtube:before {
  content: '\e922';
}
.icon--x:before {
  content: '\e923';
}
</style>
