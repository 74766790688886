import { computed, inject, Ref, ref, watchEffect } from 'vue'
import { User } from 'firebase/auth'
import { UserInjectionKey } from '@/injectionKeys'
import { db } from '@/services/firebase'
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot
} from 'firebase/firestore'
import { useMainStore } from '@/state/index'

// Define your own type that extends User to include favorites
interface ExtendedUser extends User {
  favorites?: string[] // Make 'favorites' optional
}

export function useUser() {
  const currentUser = inject<Ref<ExtendedUser | null>>(UserInjectionKey)
  const store = useMainStore()

  if (!currentUser) {
    throw new Error(
      'useUser must be used within a provider that provides "currentUser"'
    )
  }

  const userUid = computed(() => currentUser.value?.uid)
  const userName = computed(() => currentUser.value?.displayName ?? 'Guest')
  const userEmail = computed(() => currentUser.value?.email)

  // Initialize an empty array for user favorites
  const userFavorites = ref<string[]>([])

  // Fetch user favorites from Firestore
  async function getUserFavorites(): Promise<string[]> {
    if (!userUid.value) return []

    const userFavoritesRef = doc(db, 'users', userUid.value)
    const userFavoritesSnap = await getDoc(userFavoritesRef)

    if (userFavoritesSnap.exists()) {
      return userFavoritesSnap.data().favorites || []
    } else {
      return []
    }
  }

  // Real-time sync of user favorites
  const syncUserFavorites = () => {
    if (userUid.value) {
      const userFavoritesRef = doc(db, 'users', userUid.value)
      onSnapshot(userFavoritesRef, (docSnap) => {
        if (docSnap.exists()) {
          userFavorites.value = docSnap.data().favorites || []
          store.setFavoriteStatus(userFavorites.value) // Sync with Pinia store
        } else {
          userFavorites.value = []
          store.setFavoriteStatus([]) // Clear store favorites if none exist
        }
      })
    }
  }

  // Trigger real-time sync on component mount and whenever userUid changes
  watchEffect(() => {
    if (userUid.value) {
      syncUserFavorites() // Sync favorites once userUid is available
    }
  })

  // Update user favorites in Firestore
  async function updateUserFavorites(sessionId: string, isFavorite: boolean) {
    if (!userUid.value) return

    const userFavoritesRef = doc(db, 'users', userUid.value)

    try {
      if (isFavorite) {
        await updateDoc(userFavoritesRef, {
          favorites: arrayUnion(sessionId)
        })
      } else {
        await updateDoc(userFavoritesRef, {
          favorites: arrayRemove(sessionId)
        })
      }

      // Firebase will trigger the snapshot update
    } catch (error) {
      console.error('Error updating favorites:', error)
    }
  }

  return {
    currentUser,
    userUid,
    userName,
    userEmail,
    userFavorites,
    getUserFavorites,
    updateUserFavorites
  }
}
